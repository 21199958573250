import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const Play = (props: IconProps) => (
  <SVGContainer {...props} filled>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.5 5.65106C4.5 4.22506 6.029 3.32106 7.279 4.00806L18.819 10.3561C20.114 11.0681 20.114 12.9291 18.819 13.6411L7.28 19.9891C6.03 20.6761 4.501 19.7721 4.501 18.3461L4.5 5.65106Z'
    />
  </SVGContainer>
)

export default Play
