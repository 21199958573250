const COMMON_STYLES = ''

export const TEXT_SIZES = {
  xs: `${COMMON_STYLES} text-xs`,
  sm: `${COMMON_STYLES} text-sm`,
  base: `${COMMON_STYLES} text-base`,
  lg: `${COMMON_STYLES} text-lg`,
  xl: `${COMMON_STYLES} text-xl`,
  '2xl': `${COMMON_STYLES} text-2xl`,
  '3xl': `${COMMON_STYLES} text-3xl`
}
