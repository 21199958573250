import { MouseEventHandler } from 'react'
import { getCardStylesBySize } from '../../utils'
import { Icons, Text } from '@/src/ui'
import { CardSizes } from '../../constants'
import { TextSizes, TextWeights } from '@/src/ui/typography/text'

type CardHeaderProps = {
  onClose?: MouseEventHandler<HTMLButtonElement>
  size: CardSizes
  title: string
}

const CardHeader = ({ onClose, size, title }: CardHeaderProps) => {
  return (
    <div
      className={`${getCardStylesBySize(
        size
      )} flex flex-row gap-4 justify-between`}>
      <Text size={TextSizes.XL} weight={TextWeights.FONT_MEDIUM}>
        {title}
      </Text>

      {onClose && (
        <button onClick={onClose}>
          <Icons.Close color='text-primary/80' size={16} />
        </button>
      )}
    </div>
  )
}

export default CardHeader
