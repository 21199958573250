import { memo, useState, useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'

type PortalProps = {
  children: React.ReactNode
  wrapperId: string
}

const createWrapperAndAppendToBody = (wrapperId: string) => {
  const wrapperElement = document.createElement('div')
  wrapperElement.setAttribute('id', wrapperId.toString())
  document.body.appendChild(wrapperElement)
  return wrapperElement
}

const Portal = (props: PortalProps) => {
  const { children, wrapperId } = props
  const [wrapper, setWrapper] = useState<HTMLElement | null>(null)

  useLayoutEffect(() => {
    let portalContainer = document.getElementById(wrapperId)

    if (!portalContainer) {
      portalContainer = createWrapperAndAppendToBody(wrapperId)
    }

    setWrapper(portalContainer)
  }, [wrapperId])

  if (wrapper === null) return null

  return createPortal(children, wrapper)
}

export default memo(Portal)
