import toast from 'react-hot-toast'

export const showToastError = (error: Error, options = {}) => {
  toast.error(error.message, {
    ...options
  })
}

export const showToastSuccess = (message: string, options = {}) => {
  toast.success(message, {
    ...options
  })
}

export const showToastInfo = (message: string, options = {}) => {
  toast(message, {
    ...options,
    style: {
      padding: '16px'
    },
    icon: 'ℹ️'
  })
}
