import { Portal } from '@/src/ui'
import { memo, useEffect } from 'react'

type ModalProps = {
  children: React.ReactNode
  isOpen?: boolean
  onClose?: () => void
}

const Modal = ({ children, isOpen = false, onClose }: ModalProps) => {
  useEffect(() => {
    if (onClose) {
      const closeOnEscapeKey = (e: KeyboardEvent) =>
        e.key === 'Escape' ? onClose() : null

      document.body.addEventListener('keydown', closeOnEscapeKey)
      return () => {
        document.body.removeEventListener('keydown', closeOnEscapeKey)
      }
    }
  }, [onClose])

  if (!isOpen) return null

  return (
    <Portal wrapperId='modal-container'>
      <div className='fixed overscroll-auto	z-50 left-0 top-0 w-screen h-screen flex items-center justify-center'>
        <div className='z-[10000] h-full flex justify-center items-center'>
          {children}
        </div>

        <div
          className='fixed bg-black w-screen h-screen left-0 top-0 cursor-default opacity-80'
          onClick={onClose}
        />
      </div>
    </Portal>
  )
}

export default memo(Modal)
