import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const CubeTransparent = (props: IconProps) => (
  <SVGContainer {...props}>
    <path
      d='M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25'
      strokeWidth='1.5px'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SVGContainer>
)

export default CubeTransparent
