import { Trans } from '@lingui/macro'
import { ChangeEvent, forwardRef, memo } from 'react'
import Icon from '../icon'

import {
  HELPER_TEXT_SIZES,
  HELPER_TEXT_TYPES,
  INPUT_SIZES,
  INPUT_TYPES,
  LABEL_SIZES,
  LABEL_TYPES
} from './input-select.styles'
import { ICONS_KEY } from '@/src/ui/icon/constants'
import { InputSizes, InputTypes } from '@/src/ui/input'

export type InputProps = {
  className?: string
  defaultValue?: string
  helperText?: string
  icon?: ICONS_KEY
  inputType?: InputTypes
  inputSize?: InputSizes
  label?: string
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  options?: string[]
  placeholder?: string
  value?: string | undefined
} & React.InputHTMLAttributes<HTMLSelectElement>

const InputSelect = forwardRef<HTMLSelectElement, InputProps>((props, ref) => {
  const {
    className,
    icon = null,
    inputType = InputTypes.PRIMARY,
    helperText = null,
    label = null,
    onChange = undefined,
    options = [],
    placeholder,
    inputSize = InputSizes.DEFAULT,
    ...attrs
  } = props
  const labelClasses = `${LABEL_TYPES[inputType]} ${LABEL_SIZES[inputSize]}`
  const inputClasses = `${INPUT_TYPES[inputType]} ${INPUT_SIZES[inputSize]}`
  const helpTextClasses = `${HELPER_TEXT_TYPES[inputType]} ${HELPER_TEXT_SIZES[inputSize]}`
  const hasIconClasses = icon ? 'pl-10' : ''

  return (
    <div className='flex flex-col gap-2'>
      {label && <label className={labelClasses}>{label}</label>}
      <div className='relative flex'>
        {icon && (
          <div className='absolute top-1/2 -translate-y-1/2 left-3 pointer-events-none flex items-center'>
            <Icon color='gray-500' iconKey={icon} />
          </div>
        )}
        <select
          className={`${hasIconClasses} ${inputClasses} ${className}`}
          onChange={onChange}
          ref={ref}
          {...attrs}>
          <option value={''}>
            {' '}
            -- <Trans>select an option</Trans> --{' '}
          </option>
          {options.map(option => (
            <option key={option}>{option}</option>
          ))}
        </select>
      </div>
      {helperText && <span className={helpTextClasses}>{helperText}</span>}
    </div>
  )
})

InputSelect.displayName = 'InputSelect'

export default memo(InputSelect)
