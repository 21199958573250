import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const Fire = (props: IconProps) => (
  <SVGContainer {...props}>
    <path
      d='M12 21.0002C14.1218 21.0002 16.1567 20.1573 17.657 18.657C18.401 17.9149 18.991 17.0331 19.3931 16.0623C19.7952 15.0915 20.0014 14.0508 20 13C20.0013 11.9491 19.7949 10.9084 19.3926 9.93756C18.9903 8.96674 18.4002 8.08499 17.656 7.343C16.09 5.777 14 5 11.986 3C9.50002 5 9.00002 8 9.00002 10C7.00002 9 6.34302 7.343 6.34302 7.343C4.84269 8.84333 3.99982 10.8782 3.99982 13C3.99982 15.1218 4.84269 17.1567 6.34302 18.657C7.84335 20.1573 9.87824 21.0002 12 21.0002Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.1277 16.8702C10.6563 16.7268 10.2274 16.4695 9.879 16.121C9.293 15.536 9 14.768 9 14H11L12.015 11C12.5078 11.0024 12.9923 11.1261 13.4259 11.3602C13.8595 11.5943 14.2288 11.9316 14.5011 12.3423C14.7733 12.753 14.9402 13.2245 14.987 13.715C15.0338 14.2056 14.9591 14.7001 14.7694 15.1548C14.5797 15.6096 14.2809 16.0107 13.8994 16.3226C13.5179 16.6345 13.0655 16.8476 12.5821 16.9431C12.0986 17.0386 11.5991 17.0135 11.1277 16.8702Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SVGContainer>
)

export default Fire
