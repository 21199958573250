import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const Plus = (props: IconProps) => (
  <SVGContainer {...props}>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M12 4.5v15m7.5-7.5h-15'
    />
  </SVGContainer>
)

export default Plus
