import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const Gift = (props: IconProps) => (
  <SVGContainer {...props}>
    <path
      d='M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SVGContainer>
)

export default Gift
