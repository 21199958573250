import { ChangeEvent, ForwardedRef, forwardRef } from 'react'
import { InputSizes, InputTypes } from './constants'
import Icon from '../icon'

import {
  HELPER_TEXT_SIZES,
  HELPER_TEXT_TYPES,
  INPUT_SIZES,
  INPUT_TYPES,
  LABEL_SIZES,
  LABEL_TYPES
} from './input.styles'
import { ICONS_KEY } from '@/src/ui/icon/constants'
import useAutocomplete from '@/hooks/autocomplete'

export interface InputProps extends React.InputHTMLAttributes<any> {
  helperText?: string
  icon?: ICONS_KEY
  inputType?: InputTypes
  label?: string
  placeholder?: string
  inputSize?: InputSizes
  value?: string
  enableAutocomplete?: boolean
  autocompleteSuggestions?: string[]
}

const Input = forwardRef(
  (props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      className = '',
      icon = null,
      inputType = InputTypes.PRIMARY,
      helperText = null,
      label = null,
      onChange,
      placeholder,
      inputSize = InputSizes.DEFAULT,
      enableAutocomplete = false,
      autocompleteSuggestions = [],
      ...attrs
    } = props

    const {
      onChange: onAutocompleteChange,
      onClick: onAutocompleteClick,
      onKeyDown: onAutocompleteKeyDown,
      activeSuggestion,
      filteredSuggestions,
      showSuggestions,
      userInput
    } = useAutocomplete(autocompleteSuggestions)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (enableAutocomplete) {
        onAutocompleteChange(e)
      }

      onChange?.(e)
    }

    const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      onAutocompleteClick(e)
      onChange?.({
        currentTarget: { value: e.currentTarget.innerText },
        target: { value: e.currentTarget.innerText }
      } as ChangeEvent<HTMLInputElement>)
    }

    const labelClasses = `${LABEL_TYPES[inputType]} ${LABEL_SIZES[inputSize]}`
    const inputClasses = `${INPUT_TYPES[inputType]} ${INPUT_SIZES[inputSize]}`
    const helpTextClasses = `${HELPER_TEXT_TYPES[inputType]} ${HELPER_TEXT_SIZES[inputSize]}`
    const hasIconClasses = icon ? 'pl-10' : ''

    let suggestionsListComponent
    if (enableAutocomplete && showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className='list-none mt-6 text-sm absolute w-full bg-primary-negative border border-primary-200 rounded-md z-10'>
            {filteredSuggestions.map((suggestion, index) => {
              let className = 'px-3 py-2 text-neutral-900 cursor-pointer'
              if (index === activeSuggestion) {
                className += ' bg-primary text-primary-negative'
              }
              return (
                <li
                  className={className}
                  key={suggestion}
                  onClick={handleClick}>
                  {suggestion}
                </li>
              )
            })}
          </ul>
        )
      }
    }

    return (
      <div className='flex flex-col gap-2 w-full'>
        {label && <label className={labelClasses}>{label}</label>}
        <div className='relative flex'>
          {icon && (
            <div className='absolute top-1/2 -translate-y-1/2 left-3 pointer-events-none flex items-center'>
              <Icon color='gray-500' iconKey={icon} />
            </div>
          )}
          <input
            className={`${hasIconClasses} ${inputClasses} ${className}`}
            onChange={handleChange}
            onKeyDown={enableAutocomplete ? onAutocompleteKeyDown : undefined}
            placeholder={placeholder}
            ref={ref}
            value={enableAutocomplete ? userInput : props.value}
            {...attrs}
          />
          {suggestionsListComponent}
        </div>
        {helperText && <span className={helpTextClasses}>{helperText}</span>}
      </div>
    )
  }
)

Input.displayName = 'Input'

export default Input
