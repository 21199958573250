import { useRouter } from 'next/router'
import { zIndexes } from '@/src/constants/z-index'

const Topbar = () => {
  const router = useRouter()
  const routes = router.pathname

  return (
    <div
      className={`fixed left-80 top-0 bg-primary-negative border-b w-full flex items-center justify-between py-2 px-6 ${zIndexes.TOPBAR} border-b`}>
      <nav className='flex' aria-label='Breadcrumb'>
        <p className={'text-primary-700 text-xs'}>{routes}</p>
      </nav>
    </div>
  )
}

export default Topbar
