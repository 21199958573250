import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const ArrowTrendingUp = (props: IconProps) => (
  <SVGContainer {...props} filled>
    <path
      d='M14.5678 7.55569C14.5933 7.46053 14.6373 7.37134 14.6973 7.29321C14.7573 7.21507 14.8321 7.14952 14.9175 7.1003C15.0028 7.05108 15.097 7.01916 15.1947 7.00636C15.2924 6.99356 15.3916 7.00013 15.4868 7.02569L20.2668 8.30669C20.362 8.33211 20.4513 8.37605 20.5296 8.43601C20.6078 8.49596 20.6735 8.57076 20.7228 8.65611C20.7721 8.74146 20.8041 8.8357 20.817 8.93343C20.8299 9.03117 20.8233 9.13048 20.7978 9.22569L19.5168 14.0057C19.4628 14.1949 19.3367 14.3553 19.1656 14.4524C18.9945 14.5495 18.7921 14.5754 18.602 14.5247C18.4119 14.4739 18.2494 14.3505 18.1495 14.181C18.0495 14.0115 18.0202 13.8096 18.0678 13.6187L18.8778 10.5967C16.6868 11.9432 14.7853 13.7118 13.2838 15.7997C13.2203 15.888 13.1385 15.9614 13.0439 16.0149C12.9492 16.0684 12.8441 16.1007 12.7358 16.1095C12.6274 16.1184 12.5185 16.1035 12.4164 16.0661C12.3144 16.0287 12.2217 15.9695 12.1448 15.8927L8.99078 12.7377L4.27078 17.4577C4.12927 17.5942 3.93978 17.6697 3.74313 17.6679C3.54648 17.6661 3.35841 17.5872 3.21942 17.4481C3.08043 17.3089 3.00164 17.1208 3.00003 16.9241C2.99841 16.7275 3.0741 16.5381 3.21078 16.3967L8.46078 11.1467C8.60141 11.0062 8.79203 10.9273 8.99078 10.9273C9.18953 10.9273 9.38016 11.0062 9.52078 11.1467L12.5948 14.2197C14.1346 12.2588 16.0124 10.5889 18.1398 9.28869L15.0978 8.47369C15.0026 8.44816 14.9134 8.40414 14.8353 8.34414C14.7572 8.28414 14.6916 8.20933 14.6424 8.12399C14.5932 8.03864 14.5613 7.94444 14.5485 7.84676C14.5357 7.74908 14.5422 7.65083 14.5678 7.55569Z'
      clipRule='evenodd'
      fill='currentColor'
      fillRule='evenodd'
    />
  </SVGContainer>
)

export default ArrowTrendingUp
