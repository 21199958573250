import { memo } from 'react'
import { SpinnerSizes } from './constants'

type SpinnerProps = {
  size?: SpinnerSizes
}

const Spinner = ({ size = SpinnerSizes.S }: SpinnerProps) => {
  return (
    <div
      className={
        size === SpinnerSizes.S
          ? 'dolfin-spinner-small'
          : size === SpinnerSizes.M
          ? 'dolfin-spinner-medium'
          : 'dolfin-spinner-large'
      }>
      <div className='loading'>
        <div className='dolphin'>
          <div className='dolphin__anim'>
            <div className='dolphin__inner'>
              <div className='dolphin__item'></div>
            </div>
            <div className='dolphin__additional'></div>
          </div>
        </div>
        <div className='wave'>
          <div className='wave__item'></div>
          <div className='wave__item wave__item2'></div>
        </div>
        <div className='dolphin dolphin--reverse'>
          <div className='dolphin__anim'>
            <div className='dolphin__inner'>
              <div className='dolphin__item'></div>
            </div>
            <div className='dolphin__additional'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Spinner)
