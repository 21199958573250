import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const Copy = (props: IconProps) => (
  <SVGContainer {...props}>
    <path
      fillRule='evenodd'
      d='M10.5 3A1.501 1.501 0 009 4.5h6A1.5 1.5 0 0013.5 3h-3zm-2.693.178A3 3 0 0110.5 1.5h3a3 3 0 012.694 1.678c.497.042.992.092 1.486.15 1.497.173 2.57 1.46 2.57 2.929V19.5a3 3 0 01-3 3H6.75a3 3 0 01-3-3V6.257c0-1.47 1.073-2.756 2.57-2.93.493-.057.989-.107 1.487-.15z'
      clipRule='evenodd'
    />
  </SVGContainer>
)

export default Copy
