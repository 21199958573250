import React, { PropsWithChildren, useEffect } from 'react'
import { Card } from '@/src/ui'

const ClientOnly = ({
  children,
  ...delegated
}: PropsWithChildren<{ className?: string }>) => {
  const [hasMounted, setHasMounted] = React.useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }
  return <div {...delegated}>{children}</div>
}

export const CardWhenMobile = ({ children }: { children: React.ReactNode }) => (
  <>
    <div className={'hidden sm:flex'}>
      <Card>{children}</Card>
    </div>

    <div className={'sm:hidden w-full'}>{children}</div>
  </>
)

export default ClientOnly
