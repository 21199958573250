import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const EllipsisHorizontal = (props: IconProps) => (
  <SVGContainer {...props}>
    <path
      d='M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SVGContainer>
)

export default EllipsisHorizontal
