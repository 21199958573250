const COMMON_STYLES =
  'flex items-center justify-center font-normal border text-center transition-all duration-600 ease-out'

export const BUTTON_TYPES = {
  primary: `${COMMON_STYLES} bg-primary border-transparent text-primary-negative hover:bg-primary/80`,
  secondary: `${COMMON_STYLES} bg-primary-negative border-primary/20 text-neutral-900 hover:border-gray-700`,
  default: `${COMMON_STYLES} bg-primary-negative border-primary text-neutral-900 hover:bg-primary hover:text-primary-negative`,
  success: `${COMMON_STYLES} bg-green-600 border-transparent text-primary-negative hover:bg-green-700`,
  danger: `${COMMON_STYLES} bg-red-600 border-transparent text-primary-negative hover:bg-red-700`,
  warning: `${COMMON_STYLES} bg-yellow-400 border-transparent text-primary-negative hover:bg-yellow-500`,
  disabled: `${COMMON_STYLES} bg-primary-100 border-transparent pointer-events-none`
}

export const BUTTON_SIZES = {
  xs: 'text-xs px-3 py-2 rounded',
  sm: 'text-sm px-3 py-2 rounded-lg',
  base: 'text-base px-5 py-2.5 rounded-lg',
  lg: 'text-lg px-5 py-3 rounded-lg',
  xl: 'text-xl px-6 py-4 rounded-lg'
}
