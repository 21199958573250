import { IconProps } from '../constants'
import SVGContainer from './svg-container'
import React from 'react'

const DotsDrag = (props: IconProps) => (
  <SVGContainer {...props} filled>
    <path d='M9.99905 12.6614C9.99905 13.7657 9.10383 14.6609 7.99953 14.6609C6.89522 14.6609 6 13.7657 6 12.6614C6 11.5571 6.89522 10.6619 7.99953 10.6619C9.10383 10.6619 9.99905 11.5571 9.99905 12.6614Z' />
    <path d='M18.002 12.6614C18.002 13.7657 17.1067 14.6609 16.0024 14.6609C14.8981 14.6609 14.0029 13.7657 14.0029 12.6614C14.0029 11.5571 14.8981 10.6619 16.0024 10.6619C17.1067 10.6619 18.002 11.5571 18.002 12.6614Z' />
    <path d='M9.99905 5.99953C9.99905 7.10383 9.10383 7.99905 7.99953 7.99905C6.89522 7.99905 6 7.10383 6 5.99953C6 4.89522 6.89522 4 7.99953 4C9.10383 4 9.99905 4.89522 9.99905 5.99953Z' />
    <path d='M18.002 5.99953C18.002 7.10383 17.1067 7.99905 16.0024 7.99905C14.8981 7.99905 14.0029 7.10383 14.0029 5.99953C14.0029 4.89522 14.8981 4 16.0024 4C17.1067 4 18.002 4.89522 18.002 5.99953Z' />
    <path d='M9.99905 19.3233C9.99905 20.4276 9.10383 21.3228 7.99953 21.3228C6.89522 21.3228 6 20.4276 6 19.3233C6 18.219 6.89522 17.3238 7.99953 17.3238C9.10383 17.3238 9.99905 18.219 9.99905 19.3233Z' />
    <path d='M18.002 19.3233C18.002 20.4276 17.1067 21.3228 16.0024 21.3228C14.8981 21.3228 14.0029 20.4276 14.0029 19.3233C14.0029 18.219 14.8981 17.3238 16.0024 17.3238C17.1067 17.3238 18.002 18.219 18.002 19.3233Z' />
  </SVGContainer>
)

export default DotsDrag
