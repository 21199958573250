import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const PlusSmall = (props: IconProps) => (
  <SVGContainer {...props} filled>
    <path
      clipRule='evenodd'
      d='M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z'
      fillRule='evenodd'
    />
  </SVGContainer>
)

export default PlusSmall
