export const getIconColorByBtnType = (btnType: string): string => {
  if (btnType === 'secondary' || btnType === 'default')
    return 'text-primary-700'
  return 'white'
}

export const getIconSizeByBtnSize = (size: string): number => {
  if (size === 'xs') return 16
  if (size === 'sm') return 16
  if (size === 'base') return 20
  return 24
}
