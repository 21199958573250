export const zIndexes = {
  BUILDER_SPLASH: 'z-50',
  DRAWERS: 'z-50',
  TOOLTIPS: 'z-[1000000]',
  FOCUSED_BUTTONS: 'z-10',
  MODALS: 'z-[100000]',
  FILTERS: 'z-40',
  GRID_FILTERS: 'z-30',
  SIDEBAR: 'z-50',
  MOBILE_MENU: 'z-[100]',
  TOPBAR: 'z-40',
  BUILDER_DRAG_ELEMENTS: 'z-50',
  BUILDER_PANELS: 'z-30',
  BUILDER_ARROWS: 'z-20',
  BUILDER_TRIGGER: 'z-10',
  BUILDER_RULES: 'z-10',
  CHAT: 'z-50',
  HELP: 'z-50'
}
