type SVGContainerProps = {
  children: React.ReactNode
  color?: string
  filled?: boolean
  size?: number | string
  className?: string
}

const SVGContainer = ({
  color = 'text-primary',
  children,
  className,
  filled = false,
  size = 24
}: SVGContainerProps) => (
  <svg
    className={`${color} ${className ?? ''}`}
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill={!filled ? 'none' : 'currentColor'}
    stroke={!filled ? 'currentColor' : 'none'}
    xmlns='http://www.w3.org/2000/svg'>
    {children}
  </svg>
)

export default SVGContainer
