import { MouseEventHandler } from 'react'
import { Button } from '@/src/ui'
import { getCardStylesBySize } from '../../utils'
import { BtnSizes, BtnTypes } from '@/src/ui/button'
import { CardSizes } from '../../constants'

type CardFooterProps = {
  primaryLabel?: string
  onPrimaryClick?: MouseEventHandler<HTMLButtonElement>
  onSecondaryClick?: MouseEventHandler<HTMLButtonElement>
  secondaryLabel?: string
  size: CardSizes
}

const CardFooter = (props: CardFooterProps) => {
  const {
    primaryLabel,
    onPrimaryClick,
    onSecondaryClick,
    secondaryLabel,
    size
  } = props

  const hasPrimaryButton = primaryLabel && onPrimaryClick
  const hasSecondaryButton = secondaryLabel && onSecondaryClick

  return (
    <div className={`${getCardStylesBySize(size)} flex gap-2 justify-end`}>
      {hasSecondaryButton && (
        <Button
          btnType={BtnTypes.SECONDARY}
          onClick={onSecondaryClick}
          size={BtnSizes.SM}>
          {secondaryLabel}
        </Button>
      )}
      {hasPrimaryButton && (
        <Button
          btnType={BtnTypes.PRIMARY}
          onClick={onPrimaryClick}
          size={BtnSizes.SM}>
          {primaryLabel}
        </Button>
      )}
    </div>
  )
}

export default CardFooter
