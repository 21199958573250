import { memo, MouseEventHandler } from 'react'
import { CARD_STYLE } from './card.styles'
import { CardBody, CardFooter, CardHeader } from './components'
import { CardSizes } from './constants'

type CardProps = {
  children: any
  className?: string
  size?: CardSizes
  title?: string
  onClose?: MouseEventHandler<HTMLButtonElement>
  footerPrimaryLabel?: string
  onFooterPrimaryClick?: MouseEventHandler<HTMLButtonElement>
  footerSecondaryLabel?: string
  onFooterSecondaryClick?: MouseEventHandler<HTMLButtonElement>
}

const Card = (props: CardProps) => {
  const {
    children,
    className = '',
    onClose,
    size = CardSizes.DEFAULT,
    title = null,
    footerPrimaryLabel,
    onFooterPrimaryClick,
    footerSecondaryLabel,
    onFooterSecondaryClick
  } = props

  const hasHeader = !!title

  const hasFooter =
    (footerPrimaryLabel && onFooterPrimaryClick) ||
    (footerSecondaryLabel && onFooterSecondaryClick)

  return (
    <div className={`${className} ${CARD_STYLE} divide-y`}>
      {hasHeader && <CardHeader size={size} onClose={onClose} title={title} />}
      <CardBody size={size}>{children}</CardBody>
      {hasFooter && (
        <CardFooter
          primaryLabel={footerPrimaryLabel}
          onPrimaryClick={onFooterPrimaryClick}
          onSecondaryClick={onFooterSecondaryClick}
          secondaryLabel={footerSecondaryLabel}
          size={size}
        />
      )}
    </div>
  )
}

export default memo(Card)
