/** @type {import('tailwindcss').Config} */

function withOpacity(variableName) {
  return ({ opacityValue }) => {
    if (opacityValue !== undefined) {
      return `rgb(var(${variableName}) / ${opacityValue})`
    }
    return `rgb(var(${variableName}))`
  }
}

module.exports = {
  darkMode: 'class',
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './layouts/**/*.{js,ts,jsx,tsx}',
    './svgs/**/*.{js,ts,jsx,tsx}',
    './src/**/*.{js,ts,jsx,tsx}',
    './utils/**/*.{js,ts,jsx,tsx}',
    './node_modules/flowbite/**/*.js',
    './node_modules/@tremor/**/*.{js,ts,jsx,tsx}'
  ],
  theme: {
    extend: {
      colors: {
        tremor: {
          brand: {
            faint: '#eef2ff', // indigo-50
            muted: '#c7d2fe', // indigo-200
            subtle: '#818cf8', // indigo-400
            DEFAULT: '#6366f1', // indigo-500
            emphasis: '#4f46e5', // indigo-700
            inverted: '#ffffff' // white
          },
          background: {
            muted: '#f9fafb', // gray-50
            subtle: '#f3f4f6', // gray-100
            DEFAULT: '#ffffff', // white
            emphasis: '#374151' // gray-700
          },
          border: {
            DEFAULT: '#e5e7eb' // gray-200
          },
          ring: {
            DEFAULT: '#e5e7eb' // gray-200
          },
          content: {
            subtle: '#9ca3af', // gray-400
            DEFAULT: '#6b7280', // gray-500
            emphasis: '#374151', // gray-700
            strong: '#111827', // gray-900
            inverted: '#ffffff' // white
          }
        },
        // dark mode
        'dark-tremor': {
          brand: {
            faint: '#eef2ff',
            muted: '#c7d2fe',
            subtle: '#818cf8',
            DEFAULT: '#6366f1',
            emphasis: '#4f46e5',
            inverted: '#ffffff'
          },
          background: {
            muted: '#282828',
            subtle: '#4b4b4b',
            DEFAULT: '#1d1d1d',
            emphasis: '#5c5c5c'
          },
          border: {
            DEFAULT: '#5c5c5c'
          },
          ring: {
            DEFAULT: '#5c5c5c'
          },
          content: {
            subtle: '#4b4b4b',
            DEFAULT: '#737373',
            emphasis: '#ffffff',
            strong: '#ffffff',
            inverted: '#ffffff'
          }
        },
        primary: {
          DEFAULT: withOpacity('--color-primary'),
          10: withOpacity('--color-primary-10'),
          30: withOpacity('--color-primary-30'),
          50: withOpacity('--color-primary-50'),
          100: withOpacity('--color-primary-100'),
          200: withOpacity('--color-primary-200'),
          300: withOpacity('--color-primary-300'),
          400: withOpacity('--color-primary-400'),
          500: withOpacity('--color-primary-500'),
          600: withOpacity('--color-primary-600'),
          700: withOpacity('--color-primary-700'),
          800: withOpacity('--color-primary-800'),
          900: withOpacity('--color-primary-900'),
          negative: withOpacity('--color-primary-negative')
        },
        secondary: withOpacity('--color-secondary'),

        // New design system

        brand: {
          50: withOpacity('--brand-50'),
          100: withOpacity('--brand-100'),
          200: withOpacity('--brand-200'),
          300: withOpacity('--brand-300'),
          400: withOpacity('--brand-400'),
          500: withOpacity('--brand-500'),
          600: withOpacity('--brand-600'),
          700: withOpacity('--brand-700'),
          800: withOpacity('--brand-800'),
          900: withOpacity('--brand-900'),
          950: withOpacity('--brand-950')
        },

        critical: {
          50: withOpacity('--critical-50'),
          100: withOpacity('--critical-100'),
          200: withOpacity('--critical-200'),
          300: withOpacity('--critical-300'),
          400: withOpacity('--critical-400'),
          500: withOpacity('--critical-500'),
          600: withOpacity('--critical-600'),
          700: withOpacity('--critical-700'),
          800: withOpacity('--critical-800'),
          900: withOpacity('--critical-900'),
          950: withOpacity('--critical-950')
        },

        neutral: {
          0: withOpacity('--neutral-0'),
          25: withOpacity('--neutral-25'),
          50: withOpacity('--neutral-50'),
          100: withOpacity('--neutral-100'),
          200: withOpacity('--neutral-200'),
          300: withOpacity('--neutral-300'),
          400: withOpacity('--neutral-400'),
          500: withOpacity('--neutral-500'),
          600: withOpacity('--neutral-600'),
          700: withOpacity('--neutral-700'),
          800: withOpacity('--neutral-800'),
          900: withOpacity('--neutral-900'),
          950: withOpacity('--neutral-950')
        },

        success: {
          50: withOpacity('--success-50'),
          100: withOpacity('--success-100'),
          200: withOpacity('--success-200'),
          300: withOpacity('--success-300'),
          400: withOpacity('--success-400'),
          500: withOpacity('--success-500'),
          600: withOpacity('--success-600'),
          700: withOpacity('--success-700'),
          800: withOpacity('--success-800'),
          900: withOpacity('--success-900'),
          950: withOpacity('--success-950')
        },

        warning: {
          50: withOpacity('--warning-50'),
          100: withOpacity('--warning-100'),
          200: withOpacity('--warning-200'),
          300: withOpacity('--warning-300'),
          400: withOpacity('--warning-400'),
          500: withOpacity('--warning-500'),
          600: withOpacity('--warning-600'),
          700: withOpacity('--warning-700'),
          800: withOpacity('--warning-800'),
          900: withOpacity('--warning-900'),
          950: withOpacity('--warning-950')
        },

        highlight: {
          50: withOpacity('--highlight-brand-50'),
          100: withOpacity('--highlight-brand-100'),
          200: withOpacity('--highlight-brand-200'),
          300: withOpacity('--highlight-brand-300'),
          400: withOpacity('--highlight-brand-400'),
          500: withOpacity('--highlight-brand-500'),
          600: withOpacity('--highlight-brand-600'),
          700: withOpacity('--highlight-brand-700'),
          800: withOpacity('--highlight-brand-800'),
          900: withOpacity('--highlight-brand-900'),
          950: withOpacity('--highlight-brand-950')
        }
      },
      boxShadow: {
        // light
        'tremor-input': '0 1px 2px 0 rgb(0 0 0 / 0.05)',
        'tremor-card':
          '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
        'tremor-dropdown':
          '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
        // dark
        'dark-tremor-input': '0 1px 2px 0 rgb(0 0 0 / 0.05)',
        'dark-tremor-card':
          '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
        'dark-tremor-dropdown':
          '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
      },
      borderRadius: {
        'tremor-small': '0.375rem',
        'tremor-default': '0.5rem',
        'tremor-full': '9999px'
      },
      fontSize: {
        'tremor-label': ['0.75rem'],
        'tremor-default': ['0.875rem', { lineHeight: '1.25rem' }],
        'tremor-title': ['1.125rem', { lineHeight: '1.75rem' }],
        'tremor-metric': ['1.875rem', { lineHeight: '2.25rem' }]
      },
      fontFamily: {
        inter: ['var(--font-inter)', 'sans-serif'],
        'inter-tight': ['var(--font-inter-tight)', 'sans-serif']
      },
      animation: {
        'infinite-scroll': 'infinite-scroll 60s linear infinite',
        'infinite-scroll-inverse': 'infinite-scroll-inverse 60s linear infinite'
      },
      keyframes: {
        'infinite-scroll': {
          from: { transform: 'translateX(0)' },
          to: { transform: 'translateX(-100%)' }
        },
        'infinite-scroll-inverse': {
          from: { transform: 'translateX(-100%)' },
          to: { transform: 'translateX(0)' }
        }
      }
    }
  },
  safelist: [
    {
      pattern:
        /^(bg-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
      variants: ['hover', 'ui-selected']
    },
    {
      pattern:
        /^(text-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
      variants: ['hover', 'ui-selected']
    },
    {
      pattern:
        /^(border-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
      variants: ['hover', 'ui-selected']
    },
    {
      pattern:
        /^(ring-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/
    },
    {
      pattern:
        /^(stroke-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/
    },
    {
      pattern:
        /^(fill-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/
    }
  ],
  plugins: [
    // TODO: Remove this one
    require('flowbite/plugin'),

    require('@tailwindcss/forms'),
    require('@tailwindcss/container-queries'),
    require('tailwindcss-react-aria-components'),
    require('tailwindcss-animate')
  ]
}
