import { ProfileWithJoins } from '@/types'
import { Enums, Tables } from '@/db_types'

/**
 * Checks if a profile is a manager of a team.
 *
 * @param {ProfileWithJoins} [profile] - The profile to check.
 * @param {number} [orgId] - The ID of the organization.
 * @param {number} [teamId] - The ID of the team. If not provided, checks if the profile is a manager in any team in the organization.
 * @returns {boolean} Returns true if the profile is a manager of the team (or any team in the organization if teamId is not provided), false otherwise.
 */
export const isManagerOfTeam = (
  profile?: ProfileWithJoins,
  orgId?: number,
  teamId?: number
): boolean =>
  !!profile?.teams_profiles?.some(
    tp =>
      tp.organization_id === orgId &&
      (!teamId || tp.team_id === teamId) &&
      tp.role === 'manager'
  )

/**
 * Checks if a profile has full view access to a team.
 *
 * @param {ProfileWithJoins} [profile] - The profile to check.
 * @param {number} [orgId] - The ID of the organization.
 * @param {number} [teamId] - The ID of the team.
 * @returns {boolean} Returns true if the profile has full view access to the team, false otherwise.
 */
export const hasTeamFullViewAccess = (
  profile: ProfileWithJoins,
  orgId: number,
  teamId?: number
): boolean =>
  !!profile?.teams_profiles?.some(
    tp =>
      tp.organization_id === orgId &&
      (!teamId || tp.team_id === teamId) &&
      (tp.full_view_access || tp.role === 'manager')
  )

/**
 * Check profile org role
 *
 * @param {ProfileWithJoins} [profile] - The profile to check.
 * @param {number} [orgId] - The ID of the organization.
 * @returns {string | undefined} Returns the role of the profile in the organization, or undefined if the profile is not in the organization.
 */
export const getOrgRole = (
  profile: ProfileWithJoins,
  orgId: number
): Enums<'org_role'> | undefined =>
  profile?.organizations_profiles.find(org => org.organization_id === orgId)
    ?.role

/**
 * Get selected org profile
 * @param {ProfileWithJoins} [profile] - The profile to check.
 * @param {number} [orgId] - The ID of the organization.
 */
export const getSelectedOrgProfile = (
  profile: ProfileWithJoins,
  orgId: number
): Tables<'organizations_profiles'> | undefined =>
  profile?.organizations_profiles.find(org => org.organization_id === orgId)

/**
 * Get teams with full view access or manager role
 *
 * @param {ProfileWithJoins} [profile] - The profile to check.
 * @param {number} [orgId] - The ID of the organization.
 * @returns {number[]} Returns the IDs of the teams the profile has full view access or manager role in.
 */
export const getTeamsIds = (
  profile: ProfileWithJoins,
  orgId: number
): number[] =>
  profile?.teams_profiles
    ?.filter(
      tp =>
        tp.organization_id === orgId &&
        (tp.role === 'manager' || tp.full_view_access)
    )
    .map(tp => tp.team_id) ?? []
