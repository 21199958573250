import { SVGProps } from 'react'

const DolfinLogo = ({
  className,
  viewBox = '0 0 118 24',
  ...props
}: SVGProps<any>) => {
  return (
    <svg
      className={`fill-current ${className}`}
      {...props}
      viewBox={viewBox}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1416_23072)'>
        <path
          d='M0.015625 0.648438V7.34544C2.34583 7.14552 4.69143 6.88486 7.02986 7.06728C9.01282 7.33612 11.0149 7.89412 12.6916 9.011C13.4754 9.56428 13.9503 10.4332 14.6732 11.043C15.27 11.3844 16.4296 11.5818 16.3294 12.4607C16.0996 13.4018 14.9877 12.8951 14.3267 12.8416C13.0654 12.8534 11.8481 13.279 10.6095 13.4828C9.47127 13.7802 8.09067 13.7832 7.26929 14.7382C6.62675 16.4792 5.5857 18.2256 3.83987 19.0509C3.46739 19.2438 1.95703 19.9669 2.50143 19.0509C3.12322 18.1919 3.57376 17.1631 3.65215 16.1009C3.37616 15.4984 2.41877 16.5109 1.97053 16.6738C1.30777 17.1139 0.659171 17.5741 0.015625 18.0412V23.353H8.78995C10.3633 23.353 11.8257 23.0419 13.1771 22.4195C14.5487 21.7972 15.7388 20.9641 16.7473 19.9202C17.7761 18.8763 18.5728 17.6718 19.1376 16.3068C19.7225 14.9216 20.015 13.4863 20.015 12.0007C20.015 10.4951 19.7225 9.05977 19.1376 7.69469C18.5526 6.30953 17.7458 5.10504 16.7171 4.08123C15.7085 3.03734 14.5185 2.20423 13.1469 1.58192C11.7954 0.959601 10.3431 0.648438 8.78995 0.648438H0.015625Z'
          className={'fill-current'}
        />
        <path
          d='M28.9682 12.0003C28.9682 12.9036 29.1396 13.7367 29.4825 14.4996C29.8254 15.2624 30.2894 15.9249 30.8743 16.487C31.4593 17.0491 32.135 17.4907 32.9015 17.8119C33.6881 18.113 34.5151 18.2636 35.3825 18.2636C36.2498 18.2636 37.0668 18.113 37.8332 17.8119C38.6199 17.4907 39.3057 17.0491 39.8907 16.487C40.4958 15.9249 40.9698 15.2624 41.3127 14.4996C41.6556 13.7367 41.8271 12.9036 41.8271 12.0003C41.8271 11.0969 41.6556 10.2638 41.3127 9.50096C40.9698 8.73812 40.4958 8.07565 39.8907 7.51356C39.3057 6.95146 38.6199 6.51985 37.8332 6.21873C37.0668 5.89754 36.2498 5.73694 35.3825 5.73694C34.5151 5.73694 33.6881 5.89754 32.9015 6.21873C32.135 6.51985 31.4593 6.95146 30.8743 7.51356C30.2894 8.07565 29.8254 8.73812 29.4825 9.50096C29.1396 10.2638 28.9682 11.0969 28.9682 12.0003ZM22.7656 12.0003C22.7656 10.314 23.0783 8.74815 23.7036 7.30277C24.3289 5.83731 25.1962 4.56257 26.3056 3.47853C27.415 2.39449 28.7362 1.55135 30.2692 0.949107C31.8223 0.326789 33.5268 0.015625 35.3825 0.015625C37.218 0.015625 38.9124 0.326789 40.4655 0.949107C42.0187 1.55135 43.35 2.39449 44.4594 3.47853C45.5889 4.56257 46.4664 5.83731 47.0917 7.30277C47.717 8.74815 48.0296 10.314 48.0296 12.0003C48.0296 13.6866 47.717 15.2624 47.0917 16.7279C46.4664 18.1733 45.5889 19.438 44.4594 20.522C43.35 21.6061 42.0187 22.4592 40.4655 23.0815C38.9124 23.6838 37.218 23.9849 35.3825 23.9849C33.5268 23.9849 31.8223 23.6838 30.2692 23.0815C28.7362 22.4592 27.415 21.6061 26.3056 20.522C25.1962 19.438 24.3289 18.1733 23.7036 16.7279C23.0783 15.2624 22.7656 13.6866 22.7656 12.0003Z'
          className={'fill-current'}
        />
        <path
          d='M57.7115 0.664062V18.37H64.8217V23.3686H51.7812V0.664062H57.7115Z'
          className={'fill-current'}
        />
        <path
          d='M80.9535 5.64706H73.813V9.44119H80.3181V14.4398H73.813V23.353H67.8828V0.648438H80.9535V5.64706Z'
          className={'fill-current'}
        />
        <path
          d='M90.4224 0.648438V23.353H84.4922V0.648438H90.4224Z'
          className={'fill-current'}
        />
        <path
          d='M95.2031 23.353V0.648438H101.133L112.086 14.5302V0.648438H117.986V23.353H112.086L101.133 9.47131V23.353H95.2031Z'
          className={'fill-current'}
        />
      </g>
      <defs>
        <clipPath id='clip0_1416_23072'>
          <rect width='118' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DolfinLogo
