import {
  composeRenderProps,
  OverlayArrow,
  Tooltip as RCATooltip,
  TooltipProps as RCATooltipProps
} from 'react-aria-components'
import { tv } from 'tailwind-variants'
import { zIndexes } from '@/src/constants/z-index'

interface TooltipProps extends RCATooltipProps {
  type?: 'light' | 'dark'
  children: React.ReactNode
  /* Whether there is an arrow on the tooltip */
  hasArrow?: boolean
}

const styles = tv({
  base: `group rounded px-3 py-2 will-change-transform min-w-20 max-w-80 ${zIndexes.TOOLTIPS}`,
  variants: {
    isEntering: {
      true: 'animate-in fade-in placement-bottom:slide-in-from-top-0.5 placement-top:slide-in-from-bottom-0.5 placement-left:slide-in-from-right-0.5 placement-right:slide-in-from-left-0.5 ease-out duration-200'
    },
    isExiting: {
      true: 'animate-out fade-out placement-bottom:slide-out-to-top-0.5 placement-top:slide-out-to-bottom-0.5 placement-left:slide-out-to-right-0.5 placement-right:slide-out-to-left-0.5 ease-in duration-150'
    },
    type: {
      light: 'bg-neutral-0 text-neutral-800 rounded shadow',
      dark: 'bg-neutral-900 text-neutral-0'
    }
  },
  defaultVariants: {
    type: 'dark'
  }
})

const arrowStyles = tv({
  base: 'group-placement-bottom:rotate-180 group-placement-left:-rotate-90 group-placement-right:rotate-90',
  variants: {
    type: {
      light: 'fill-neutral-0',
      dark: 'fill-neutral-900'
    }
  },
  defaultVariants: {
    type: 'dark'
  }
})

const titleStyles = tv({
  base: "text-xs break-words font-semibold font-['Inter'] leading-tight",
  variants: {
    type: {
      light: 'text-neutral-800',
      dark: 'text-neutral-0'
    }
  },
  defaultVariants: {
    type: 'dark'
  }
})

const supportiveTextStyles = tv({
  base: "text-xs font-medium break-words font-['Plus Jakarta Sans'] leading-tight mt-1",
  variants: {
    type: {
      light: 'text-neutral-600',
      dark: 'text-neutral-0'
    }
  },
  defaultVariants: {
    type: 'dark'
  }
})

const Tooltip = ({
  children,
  hasArrow,
  type = 'dark',
  ...props
}: TooltipProps) => {
  return (
    <RCATooltip
      {...props}
      offset={8}
      className={composeRenderProps(props.className, (className, renderProps) =>
        styles({ ...renderProps, className, type })
      )}>
      {hasArrow && (
        <OverlayArrow>
          <svg
            width={8}
            height={8}
            viewBox='0 0 8 8'
            className={arrowStyles({ type })}>
            <path d='M0 0 L4 4 L8 0' />
          </svg>
        </OverlayArrow>
      )}
      {children}
    </RCATooltip>
  )
}

const TooltipTitle = ({
  children,
  type = 'dark'
}: {
  children: React.ReactNode
  type?: 'light' | 'dark'
}) => <p className={titleStyles({ type })}>{children}</p>

const TooltipSupportiveText = ({
  children,
  type = 'dark'
}: {
  children: React.ReactNode
  type?: 'light' | 'dark'
}) => <p className={supportiveTextStyles({ type })}>{children}</p>

Tooltip.Title = TooltipTitle
Tooltip.SupportiveText = TooltipSupportiveText

export default Tooltip
