import {
  ChangeEventHandler,
  forwardRef,
  HTMLProps,
  memo,
  useState
} from 'react'

type CheckboxProps = {
  initialState?: boolean
  checked?: boolean
  label?: string
  onChangeValue?: (checked: boolean) => void
} & HTMLProps<HTMLInputElement>

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    initialState = true,
    label = null,
    onChangeValue,
    checked: checkedFromProps,
    ...attrs
  } = props
  const [checked, setChecked] = useState(initialState)

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = () => {
    setChecked(!checked)
    onChangeValue && onChangeValue(!checked)
  }

  return (
    <div className='flex gap-2 items-center'>
      <input
        checked={checkedFromProps ?? checked}
        className='w-4 h-4 text-neutral-900 bg-primary-100 border-primary-300 rounded focus:ring-primary'
        id='checked-checkbox'
        onChange={handleOnChange}
        type='checkbox'
        ref={ref}
        {...attrs}
      />
      {label && (
        <label
          htmlFor='checked-checkbox'
          className='text-sm font-medium text-primary-900'>
          {label}
        </label>
      )}
    </div>
  )
})

Checkbox.displayName = 'Checkbox'

export default memo(Checkbox)
