import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const Dot = (props: IconProps) => (
  <SVGContainer {...props} filled>
    <path d='M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z' />
  </SVGContainer>
)

export default Dot
