import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const Close = (props: IconProps) => (
  <SVGContainer {...props}>
    <path
      d='M4 4L20 20M4 20L20 4L4 20Z'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SVGContainer>
)

export default Close
