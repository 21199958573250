import React from 'react'
import {
  Modal as RACModal,
  ModalOverlay,
  ModalOverlayProps
} from 'react-aria-components'
import { tv } from 'tailwind-variants'
import { zIndexes } from '@/src/constants/z-index'

const overlayStyles = tv({
  base: `fixed top-0 left-0 w-full h-[--visual-viewport-height] isolate z-20 bg-neutral-900/40 flex items-center justify-center p-4 text-center ${zIndexes.MODALS}`,
  variants: {
    isEntering: {
      true: 'animate-in fade-in duration-200 ease-out'
    },
    isExiting: {
      true: 'animate-out fade-out duration-200 ease-in'
    }
  }
})

const modalStyles = tv({
  variants: {
    isEntering: {
      true: 'animate-in zoom-in-105 ease-out duration-200'
    },
    isExiting: {
      true: 'animate-out zoom-out-95 ease-in duration-200'
    }
  }
})

export function Modal(props: ModalOverlayProps) {
  return (
    <ModalOverlay {...props} className={overlayStyles}>
      <RACModal {...props} className={modalStyles} />
    </ModalOverlay>
  )
}
