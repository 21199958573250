export enum TextSizes {
  XS = 'xs',
  SM = 'sm',
  BASE = 'base',
  LG = 'lg',
  XL = 'xl',
  '2XL' = '2xl',
  '3XL' = '3xl'
}

export enum TextWeights {
  FONT_LIGHT = 'font-light',
  FONT_NORMAL = 'font-normal',
  FONT_MEDIUM = 'font-medium',
  FONT_SEMIBOLD = 'font-semibold',
  FONT_BOLD = 'font-bold'
}
