import { zIndexes } from '@/src/constants/z-index'

type ContainerProps = {
  children: any
  isOpen: boolean
  onClose: React.MouseEventHandler<HTMLButtonElement>
}

const DrawerContainer = ({ children, isOpen, onClose }: ContainerProps) => {
  return (
    <div
      className={
        `${zIndexes.DRAWERS} bg-black bg-opacity-60 fixed top-0 left-0 w-full h-screen inset-0 transform ease-in-out overflow-hidden transition-opacity duration-500 ` +
        (isOpen ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full')
      }>
      <button
        className='fixed top-0 left-0 w-full h-full cursor-default'
        onClick={onClose}
      />
      <div
        className={`fixed bg-primary-negative top-0 right-0 h-screen p-6 overflow-y-auto w-[100vw] sm:w-[500px] transition-all transform ease-in-out duration-300 ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        tabIndex={-1}>
        {children}
      </div>
    </div>
  )
}

export default DrawerContainer
