export enum Sizes {
  SMALL,
  MEDIUM,
  SEMI_LARGE,
  LARGE,
  EXTRA_LARGE
}

const sizes = {
  [Sizes.SMALL]: 'w-6 h-6',
  [Sizes.MEDIUM]: 'w-8 h-8',
  [Sizes.SEMI_LARGE]: 'w-10 h-10',
  [Sizes.LARGE]: 'w-14 h-14',
  [Sizes.EXTRA_LARGE]: 'w-16 h-16'
}

const text = {
  [Sizes.SMALL]: 'text-xs',
  [Sizes.MEDIUM]: 'text-md',
  [Sizes.SEMI_LARGE]: 'text-xl',
  [Sizes.LARGE]: 'text-2xl',
  [Sizes.EXTRA_LARGE]: 'text-3xl'
}

export { sizes, text }
