export function hexToRGB(hex: string) {
  let r = '0',
    g = '0',
    b = '0'

  // 3 digits
  if (hex.length === 4) {
    r = '0x' + hex[1] + hex[1]
    g = '0x' + hex[2] + hex[2]
    b = '0x' + hex[3] + hex[3]

    // 6 digits
  } else if (hex.length === 7) {
    r = '0x' + hex[1] + hex[2]
    g = '0x' + hex[3] + hex[4]
    b = '0x' + hex[5] + hex[6]
  }

  return '' + +r + ',' + +g + ',' + +b
}

export const whiteOrBlack = (hex: string) => {
  const rgb = hexToRGB(hex)
  const rgbSplit = rgb.split(',')
  const brightness = Math.round(
    (parseInt(rgbSplit[0]) * 299 +
      parseInt(rgbSplit[1]) * 587 +
      parseInt(rgbSplit[2]) * 114) /
      1000
  )

  return brightness > 155 ? '#000000' : '#ffffff'
}
