import { BUTTON_TYPES, BUTTON_SIZES } from './button.styles'
import { getIconColorByBtnType, getIconSizeByBtnSize } from './utils'
import { Icon, Spinner } from '@/src/ui'
import { ICONS_KEY } from '@/src/ui/icon/constants'
import { PropsWithChildren } from 'react'

export enum BtnTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DEFAULT = 'default',
  SUCCESS = 'success',
  DANGER = 'danger',
  DISABLED = 'disabled',
  WARNING = 'warning'
}

export enum BtnSizes {
  XS = 'xs',
  SM = 'sm',
  BASE = 'base',
  LG = 'lg',
  XL = 'xl'
}

type ButtonProps = {
  btnType?: BtnTypes
  block?: boolean
  children: string | Element | JSX.Element
  className?: string
  icon?: ICONS_KEY | JSX.Element
  loading?: boolean
  onClick?: Function
  size?: BtnSizes
} & React.ButtonHTMLAttributes<any>

const isIconKey = (icon: ICONS_KEY | JSX.Element): icon is ICONS_KEY =>
  typeof icon !== 'object'

const Content = (props: PropsWithChildren<ButtonProps>) => {
  const { btnType, children, icon, size } = props

  if (!icon) {
    return <div>{children}</div>
  }

  const iconColor = btnType ? getIconColorByBtnType(btnType) : undefined
  const iconSize = size ? getIconSizeByBtnSize(size) : undefined

  return (
    <div className='flex flex-row justify-center items-center gap-2'>
      {!isIconKey(icon) ? (
        icon
      ) : (
        <Icon color={iconColor} iconKey={icon} size={iconSize} />
      )}
      {children}
    </div>
  )
}

const Button = (props: ButtonProps) => {
  const {
    block = false,
    btnType = 'primary',
    children,
    className = '',
    icon,
    loading = false,
    onClick = () => null,
    size = BtnSizes.SM,
    ...attrs
  } = props

  const buttonClasses = `${
    !loading ? BUTTON_TYPES[btnType] : BUTTON_TYPES.disabled
  } ${BUTTON_SIZES[size]} ${block && 'w-full'}`

  return (
    <button
      className={`${buttonClasses} ${className}`}
      onClick={e => !loading && onClick(e)}
      type='button'
      {...attrs}>
      {loading ? <Spinner /> : <Content {...props}>{children}</Content>}
    </button>
  )
}

export default Button
