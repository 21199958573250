import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const ChevronRight = (props: IconProps) => (
  <SVGContainer {...props}>
    <path
      d='M9 5L16 12L9 19'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SVGContainer>
)

export default ChevronRight
