import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const ArchiveBox = (props: IconProps) => (
  <SVGContainer {...props} filled>
    <path d='M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375z' />
    <path
      clipRule='evenodd'
      d='M3.087 9l.54 9.176A3 3 0 006.62 21h10.757a3 3 0 002.995-2.824L20.913 9H3.087zm6.163 3.75A.75.75 0 0110 12h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75z'
      fillRule='evenodd'
    />
  </SVGContainer>
)

export default ArchiveBox
