import { composeRenderProps } from 'react-aria-components'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'tailwind.config.js'

export const pressedRing = tv({
  base: 'outline-none pressed:outline-8 pressed:outline-offset-0',
  variants: {
    destructive: {
      false: ''
    },
    isDisabled: {
      false: ''
    },
    hierarchy: {
      primary: 'pressed:outline-brand-100',
      secondary: 'pressed:outline-neutral-100'
    }
  },
  compoundVariants: [
    {
      destructive: [true],
      hierarchy: 'primary',
      className: `pressed:outline-critical-50`
    },
    {
      destructive: [true],
      hierarchy: 'secondary',
      className: `pressed:outline-critical-50`
    },
    {
      isDisabled: true,
      hierarchy: 'primary',
      className: `pressed:outline-0`
    },
    {
      isDisabled: true,
      hierarchy: 'secondary',
      className: `pressed:outline-0`
    }
  ]
})

export const focusRing = tv({
  base: '',
  variants: {
    isSelected: {
      false: 'border border-neutral-300 group-focus:border-highlight-700',
      true: ''
    },
    isInvalid: {
      false: '',
      true: ''
    },
    isDisabled: {
      false: '',
      true: 'border-neutral-300 focus:outline-0'
    },
    hierarchy: {
      primary: ``,
      secondary: ``,
      tertiary: `outline-none`,
      linkColor: `outline-none`,
      linkGray: `outline-none`
    }
  },
  compoundVariants: [
    {
      isSelected: true,
      isDisabled: true,
      className: 'border-neutral-300'
    },
    {
      isSelected: false,
      isDisabled: true,
      className: 'border-neutral-300'
    },
    {
      isSelected: false,
      isInvalid: false,
      isDisabled: false,
      className: 'hover:border-highlight-300'
    },
    {
      isSelected: false,
      isInvalid: true,
      isDisabled: false,
      className: 'hover:border-rose-300'
    },
    {
      isSelected: true,
      isInvalid: true,
      isDisabled: false,
      className:
        'group-focus:outline-none group-focus:outline-4 group-focus:outline-rose-100 group-focus:outline-offset-0 focus:outline-none focus:outline-4 focus:outline-rose-100 focus:outline-offset-0'
    },
    {
      isSelected: false,
      isInvalid: true,
      isDisabled: false,
      className:
        'border border-neutral-300 group-focus:border-rose-700 group-focus:outline-none group-focus:outline-4 group-focus:outline-rose-100 group-focus:outline-offset-0 focus:outline-none focus:outline-4 focus:outline-rose-100 focus:outline-offset-0'
    },

    {
      isSelected: true,
      isInvalid: false,
      isDisabled: false,
      className:
        'group-focus:outline-none group-focus:outline-4 group-focus:outline-highlight-100 group-focus:outline-offset-0 focus:outline-none focus:outline-4 focus:outline-highlight-100 focus:outline-offset-0'
    }
  ]
})

const chipBaseTextStyles = tv({
  base: "text-center text-neutral-700 text-sm font-medium font-['Inter'] leading-tight",
  variants: {
    isDisabled: {
      true: 'opacity-40 text-neutral-500'
    }
  }
})

export const chipBaseStyles = tv({
  extend: chipBaseTextStyles,
  base: `animate-in truncate zoom-in duration-300 h-8 px-2 p-1.5 gap-1 bg-neutral-0 border border-neutral-300 rounded-lg items-center inline-flex outline-none`,
  variants: {
    squared: {
      true: 'p-1.5 w-8'
    },
    isPressed: {
      true: 'bg-brand-100 border-brand-600 outline-4 outline-offset-0 outline-brand-100'
    },
    IsDisabled: {
      true: `border-neutral-300`,
      false: ''
    },
    isSelected: {
      true: `bg-highlight-50 border-highlight-200`,
      false: ''
    },
    isFocused: {
      true: `bg-brand-100 border-brand-600`
    },
    isHovered: {
      true: `bg-brand-50 border-brand-200`
    },
    noActions: {
      true: 'cursor-default bg-neutral-0 border border-neutral-300'
    }
  },
  compoundVariants: [
    {
      isPressed: true,
      isHovered: true,
      className: 'bg-brand-100 border-brand-600'
    },
    {
      isFocused: true,
      isHovered: true,
      className: 'bg-brand-100 border-brand-600'
    },
    {
      isPressed: true,
      noActions: true,
      className: 'bg-neutral-0 border-neutral-300 outline-0'
    },
    {
      isFocused: true,
      noActions: true,
      className: 'bg-neutral-0 border-neutral-300 outline-0'
    },
    {
      isFocused: true,
      isPressed: true,
      isSelected: true,
      className: 'bg-highlight-100 border-highlight-600'
    },
    {
      isFocused: false,
      isPressed: false,
      isSelected: true,
      className: 'bg-highlight-100 border-highlight-600'
    },
    {
      isFocused: true,
      isPressed: false,
      isSelected: true,
      className: 'bg-highlight-100 border-highlight-600'
    },
    {
      isFocused: false,
      isPressed: true,
      isSelected: true,
      className: 'bg-highlight-100 border-highlight-600'
    }
  ]
})

export const groupBaseStyles = tv({
  base: 'flex items-center bg-neutral-50 align-center border rounded outline-none border-neutral-300 focus-within:outline-4 focus-within:outline-brand-100 focus-within:outline-offset-0 focus-within:border focus-within:border-brand-300 invalid:border-critical-300 invalid:focus-within:border-critical-300 invalid:focus-within:outline-critical-100 disabled:border-neutral-300'
})

export const inputBaseStyles = tv({
  base: 'aria-input grow bg-neutral-50 focus:ring-0 shadow-none focus:shadow-none focus-within:shadow-none focus:outline-none px-4 py-2.5 outline-none border-none focus:outline-none focus:outline-offset-0 invalid:border-0 focus:border-0 focus:outline-0 disabled:placeholder-neutral-400 placeholder-neutral-500 disabled:text-neutral-400 rounded max-w-full'
})

export const popoverContainerBaseStyles = tv({
  base: 'relative scrollbar-hide bg-neutral-0 rounded-lg shadow border border-neutral-100 flex-col justify-start items-start inline-flex overflow-y-scroll'
})

export const listItemBaseStyles = tv({
  base: "group relative w-full h-10 px-4 py-2.5 justify-start items-center gap-2 inline-flex text-base font-normal font-['Inter'] leading-tight group-disabled:text-neutral-400 cursor-default select-none will-change-transform text-sm forced-color-adjust-none",
  variants: {
    isFocused: {
      false: 'hover:bg-neutral-50',
      true: 'outline-none outline-0 border-0 bg-neutral-100'
    },
    isDisabled: {
      false: 'text-neutral-700',
      true: 'text-neutral-300'
    },
    isSelected: {
      false: 'hover:text-neutral-800 -outline-offset-2',
      true: 'first:rounded-t-md last:rounded-b-md bg-highlight-600 text-neutral-0 -outline-offset-4 outline-neutral-0 hover:bg-highlight-700'
    }
  },
  compoundVariants: [
    {
      isFocused: true,
      isSelected: true,
      className: 'bg-highlight-700'
    }
  ]
})

export function composeTailwindRenderProps<T>(
  className: string | ((v: T) => string) | undefined,
  tw: string
): string | ((v: T) => string) {
  return composeRenderProps(className, className => twMerge(tw, className))
}

const fullConfig = resolveConfig(
  tailwindConfig as unknown as import('tailwindcss').Config
)

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export const getBreakpointValue = (value: Breakpoint): number => {
  if (fullConfig && fullConfig.theme && fullConfig.theme.screens) {
    const screenWidth = fullConfig.theme.screens[value]
    if (screenWidth) {
      return parseInt(screenWidth.slice(0, screenWidth.indexOf('px')), 10)
    }
  }
  return 0
}

export const getCurrentBreakpoint = (): Breakpoint => {
  let currentBreakpoint: Breakpoint = 'sm'
  let biggestBreakpointValue = 0
  for (const breakpoint of Object.keys(
    fullConfig.theme.screens
  ) as Breakpoint[]) {
    const breakpointValue = getBreakpointValue(breakpoint)
    if (typeof window === 'object' && window.innerWidth >= breakpointValue) {
      if (breakpointValue >= biggestBreakpointValue) {
        biggestBreakpointValue = breakpointValue
        currentBreakpoint = breakpoint
      }
    }
  }

  return currentBreakpoint
}

export const isMobileBreakpoint = (): boolean => {
  return ['sm', 'md'].includes(getCurrentBreakpoint())
}

export const getInitials = (name?: string): string | undefined => {
  return name
    ?.trim()
    ?.split(' ')
    ?.map(i => i[0])
    ?.join('')
    ?.toUpperCase()!
}
