import { memo, PropsWithChildren, ReactNode } from 'react'
import Link from 'next/link'
import { BUTTON_LINK_TYPES, BUTTON_LINK_SIZES } from './styles'
import {
  ButtonLinkSizes,
  ButtonLinkTypes,
  ButtonLinkWeights
} from './constants'

export type ButtonLinkProps = {
  btnType?: ButtonLinkTypes
  className?: string
  href: string
  size?: ButtonLinkSizes
  weight?: ButtonLinkWeights
} & React.ButtonHTMLAttributes<any>

const ButtonLink = (props: PropsWithChildren<ButtonLinkProps>) => {
  const {
    btnType = ButtonLinkTypes.PRIMARY,
    children,
    className = '',
    href,
    size = ButtonLinkSizes.SM,
    weight = ButtonLinkWeights.FONT_NORMAL,
    ...attrs
  } = props
  const buttonLinkClasses = `${BUTTON_LINK_TYPES[btnType]} ${BUTTON_LINK_SIZES[size]}`

  return (
    <Link
      href={href}
      className={`${buttonLinkClasses} ${weight} ${className}`}
      {...attrs}>
      {children}
    </Link>
  )
}

export default memo(ButtonLink)
