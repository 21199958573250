export const HEADING_SIZES = {
  base: `text-base`,
  lg: `text-lg`,
  md: `text-md`,
  xl: `text-xl`,
  '2xl': `text-2xl`,
  '3xl': `text-3xl`,
  '4xl': `text-4xl`,
  '5xl': `text-5xl`
}
