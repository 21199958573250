export enum InputSizes {
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large'
}

export enum InputTypes {
  DANGER = 'danger',
  PRIMARY = 'primary',
  SUCCESS = 'success',
  DISABLED = 'disabled'
}
