import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const ArrowSmallRight = (props: IconProps) => (
  <SVGContainer {...props} filled>
    <path
      clipRule='evenodd'
      d='M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z'
      fillRule='evenodd'
    />
  </SVGContainer>
)

export default ArrowSmallRight
