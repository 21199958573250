// -------------------------------------------------------------------------
// TODO: Create active, focus and hover states
// -------------------------------------------------------------------------

// Input

const COMMON_INPUT_STYLES =
  'appearance-none rounded-lg border font-normal w-full'

export const INPUT_TYPES = {
  primary: `${COMMON_INPUT_STYLES} bg-primary-negative border-primary-200 text-primary-500 placeholder:text-primary-300`,
  success: `${COMMON_INPUT_STYLES} bg-green-100 border-green-500 text-green-500 placeholder:text-green-300`,
  danger: `${COMMON_INPUT_STYLES} bg-red-100 border-red-500 text-red-500 placeholder:text-red-300`,
  disabled: `${COMMON_INPUT_STYLES} bg-primary-100 border-primary-200 text-primary-500 placeholder:text-primary-300`
}

export const INPUT_SIZES = {
  small: 'text-sm px-4 py-2',
  default: 'text-base px-4 py-3',
  large: 'text-base p-4'
}

// Label

const COMMON_LABEL_STYLES = 'label'

export const LABEL_TYPES = {
  primary: `${COMMON_LABEL_STYLES} text-primary-600`,
  success: `${COMMON_LABEL_STYLES} text-green-600`,
  danger: `${COMMON_LABEL_STYLES} text-red-600`,
  disabled: `${COMMON_LABEL_STYLES} text-primary-600`
}

export const LABEL_SIZES = {
  small: 'text-sm',
  default: 'text-sm',
  large: 'text-sm'
}

// Helper Text

const COMMON_HELPER_TEXT_STYLES = 'text-sm'

export const HELPER_TEXT_TYPES = {
  primary: `${COMMON_HELPER_TEXT_STYLES} font-normal text-primary-400`,
  danger: `${COMMON_HELPER_TEXT_STYLES} font-medium text-red-600`,
  success: `${COMMON_HELPER_TEXT_STYLES} font-medium text-green-600`,
  disabled: `${COMMON_HELPER_TEXT_STYLES} font-normal text-primary-400`
}

export const HELPER_TEXT_SIZES = {
  small: 'text-sm',
  default: 'text-sm',
  large: 'text-sm'
}
