import { EventHandler, ReactNode, useEffect } from 'react'
import { Portal } from '@/src/ui'
import DrawerHeader from './components/drawer-header'
import DrawerContainer from './components/drawer-container'

type DrawerProps = {
  children: ReactNode | ReactNode[]
  isOpen?: boolean
  onClose?: EventHandler<any>
  title?: string
} & React.HTMLAttributes<{ id: string }>

const Drawer = ({
  children,
  isOpen = false,
  onClose = () => {},
  title = ''
}: DrawerProps) => {
  useEffect(() => {
    if (onClose) {
      const closeOnEscapeKey = (e: Event) =>
        (e as KeyboardEvent).key === 'Escape' ? onClose(e) : null

      document.body.addEventListener('keydown', closeOnEscapeKey)
      return () => {
        document.body.removeEventListener('keydown', closeOnEscapeKey)
      }
    }
  }, [onClose])

  return (
    <Portal wrapperId='drawer-container'>
      <DrawerContainer isOpen={isOpen} onClose={onClose}>
        <DrawerHeader title={title} onClick={onClose} />
        {children}
      </DrawerContainer>
    </Portal>
  )
}

export default Drawer
