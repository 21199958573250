import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const ChevronDown = (props: IconProps) => (
  <SVGContainer {...props}>
    <path
      d='M19 9L12 16L5 9'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SVGContainer>
)

export default ChevronDown
