import { useEffect, useState } from 'react'
import { useOrgContext } from '@/hooks/state/organization'
import { Database, Tables, TablesInsert } from '@/db_types'
import NotificationItem from '@/src/components/notifications/notification-item'
import useSupabaseTable from '@/hooks/supabase-table'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { QueryKeys } from '@/hooks/queryKeys'
import { t } from '@lingui/macro'
import { useQueryClient } from 'react-query'
import { NotificationInsert } from '@/types'

export type NotificationsQuery = Tables<'notifications'> & {
  notifications_status: Tables<'notifications_status'>[]
  profiles: Tables<'profiles'>
  comments: (Tables<'comments'> & {
    profiles: Tables<'profiles'>
    comments_mentions: (Tables<'comments_mentions'> & {
      profiles: Tables<'profiles'>
    })[]
  })[]
}

interface useNotificationsProps {
  entity_type?: string
  entity_id?: number
  profile_id?: string
  enabled?: boolean
  type?: Database['public']['Enums']['notification_type']
}

function useNotifications({ enabled = true, ...props }: useNotificationsProps) {
  const [writingComment, setWritingComment] = useState<number>()
  const selectedOrganizationId = useOrgContext(state => state.id)
  const currentUserId = useOrgContext(state => state.profile?.id)
  const supabase = useSupabaseClient<Database>()
  const queryClient = useQueryClient()

  const [permissionsGranted, setPermissionsGranted] = useState(false)

  const { items: notifications, fetchItems: fetchNotifications } =
    useSupabaseTable<NotificationsQuery, TablesInsert<'notifications'>>({
      queryKeys: [
        QueryKeys.notifications.get,
        props?.entity_id,
        props?.entity_type,
        props?.profile_id
      ],
      enabled: enabled,
      tableName: 'notifications',
      selectQuery:
        '*, notifications_status!inner(*), profiles!notifications_profile_id_fkey(*), comments(*, profiles!comments_profile_id_fkey(*), comments_mentions(*, profiles(*)))',
      eqFilters: [
        { column: 'organization_id', value: selectedOrganizationId! },
        { column: 'notifications_status.profile_id', value: currentUserId! },
        ...(props?.profile_id
          ? [{ column: 'profile_id', value: props.profile_id }]
          : []),
        ...(props?.type ? [{ column: 'type', value: props.type }] : []),
        ...(props?.entity_id && props?.entity_type
          ? [
              { column: 'entity_id', value: props.entity_id },
              { column: 'entity_type', value: props.entity_type }
            ]
          : [])
      ],
      order: [
        {
          column: 'created_at',
          options: {
            ascending: false
          }
        }
      ]
    })

  useEffect(() => {
    if (enabled) {
      const askForPermission = async () => {
        const permission = window.Notification
          ? await Notification.requestPermission()
          : undefined
        setPermissionsGranted(permission === 'granted')
      }

      askForPermission()
    }
  }, [enabled, selectedOrganizationId])

  useEffect(() => {
    if (notifications) {
      const channels = ['notifications_status', 'comments'].map(table =>
        supabase
          .channel(table)
          .on(
            'postgres_changes',
            {
              event: 'INSERT',
              schema: 'public',
              table
            },
            n => {
              fetchNotifications().then(data => {
                const notification = (
                  data.items as unknown as NotificationsQuery[]
                )?.find(nn => nn.id === n.new.notification_id)
                if (notification && permissionsGranted) {
                  const userName =
                    n.table === 'comments'
                      ? notification.comments?.find(c => c.id === n.new.id)
                          ?.profiles?.name
                      : notification.profiles?.name

                  if (Notification) {
                    new Notification(t`New notification from ${userName}`)
                  }
                }
                if (notification) {
                  queryClient.invalidateQueries(QueryKeys.notifications.get)
                }
              })
            }
          )
          .subscribe()
      )

      return () => {
        channels.forEach(c => c.unsubscribe())
      }
    }
  }, [
    fetchNotifications,
    notifications,
    permissionsGranted,
    queryClient,
    supabase
  ])

  const unreadNotifications = notifications?.filter(n =>
    n.notifications_status?.find(ns => ns.status === 'unread')
  ).length

  const insertNotification = async ({
    comment,
    mentions,
    type,
    entity_type,
    entity_id
  }: {
    comment?: string
    mentions?: string[]
  } & TablesInsert<'notifications'>) => {
    const notificationToInsert = {
      type,
      entity_type,
      entity_id,
      organization_id: selectedOrganizationId,
      profile_id: currentUserId
    } as NotificationInsert

    const { data: notification } = await supabase
      .from('notifications')
      .insert(notificationToInsert)
      .select('id')
      .limit(1)
      .single()

    if (notification && comment) {
      const commentToInsert = {
        notification_id: notification.id,
        content: comment,
        profile_id: currentUserId!
      }

      const { data: insertedComment } = await supabase
        .from('comments')
        .insert(commentToInsert)
        .select('id')
        .limit(1)
        .single()

      if (insertedComment && mentions) {
        const mentionsToInsert = mentions.map(m => ({
          comment_id: insertedComment.id,
          profile_id: m
        }))

        await supabase.from('comments_mentions').insert(mentionsToInsert)
      }
    }
  }

  return {
    notifications,
    writingComment,
    setWritingComment,
    NotificationItem,
    unreadNotifications,
    insertNotification
  }
}

export default useNotifications
