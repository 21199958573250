import { ChevronLeftDouble } from '@untitled-ui/icons-react'
import { t } from '@lingui/macro'

interface SubSidebarProps {
  mobile?: boolean
  visible?: boolean
  onClose: () => void
  full?: boolean
  label: string
  children: React.ReactNode
}
const SubSidebar = ({
  label,
  full,
  children,
  visible,
  onClose
}: SubSidebarProps) => {
  const mobileStyle = visible ? 'flex items-stretch w-full pl-6' : 'hidden'
  const desktopStyle = `absolute overflow-hidden bg-gray-900 w-0 left-full top-0 transition-all duration-500 ease-out h-screen flex-col py-8 
        ${
          visible &&
          'md:group-hover:flex md:group-hover:w-60 transition-all duration-500 ease-out'
        }`
  return (
    <div className={full ? mobileStyle : desktopStyle}>
      {!full && (
        <button
          className='absolute right-2 top-2 flex justify-center items-center cursor-pointer'
          onClick={onClose}>
          <ChevronLeftDouble
            className='text-neutral-0 w-5 h-5'
            aria-label={t`Close ${label}`}
          />
        </button>
      )}
      {!full && (
        <label
          className={
            "block truncate px-4 text-lg font-bold font-['Inter'] space-y-2 text-neutral-0 leading-snug"
          }>
          {label}
        </label>
      )}
      <ul className={`space-y-2 ${full ? 'py-2 w-full' : 'px-0 py-8'}`}>
        {children}
      </ul>
    </div>
  )
}

export default SubSidebar
