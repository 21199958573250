import { t } from '@lingui/macro'
import { ICONS_BY_KEY, ICONS_KEY } from './constants'

type IconProps = {
  color?: string
  iconKey: ICONS_KEY
  size?: number | string
  className?: string
  width?: number | string
}

const Icon = ({ iconKey, ...rest }: IconProps) => {
  if (!ICONS_BY_KEY[iconKey])
    throw new Error(t`Icon key ${iconKey} not found inside Icon's component!`)

  const Component = ICONS_BY_KEY[iconKey]
  return <Component {...rest} />
}

export default Icon
