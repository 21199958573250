import * as Icons from './components'
import { CheckBadgeIcon } from '@heroicons/react/24/solid'

export type IconProps = {
  className?: string
  color?: string
  filled?: boolean
  size?: number | string
}

export enum ICONS_KEY {
  ArchiveBox = 'archive-box',
  ArrowPointingOut = 'arrow-pointing-out',
  ArrowSmallDown = 'arrow-small-down',
  ArrowSmallLeft = 'arrow-small-left',
  ArrowSmallRight = 'arrow-small-right',
  ArrowTrendingUp = 'arrow-trending-up',
  ArrowUturnRight = 'arrow-uturn-right',
  CheckCircle = 'check-circle',
  ChevronDown = 'chevron-down',
  CheckBadge = 'check-badge',
  ChevronUp = 'chevron-up',
  ChevronRight = 'chevron-right',
  ChevronLeft = 'chevron-left',
  CircleArrowDown = 'circle-arrow-down',
  Close = 'close',
  Cog = 'cog',
  Cog8Tooth = 'cog-8-tooth',
  Copy = 'copy',
  CloudArrowDown = 'cloud-arrow-down',
  CubeTransparent = 'cube-transparent',
  CursorArrowRays = 'cursor-arrow-rays',
  Db = 'db',
  Detail = 'detail',
  DocumentCheck = 'document-check',
  Dot = 'dot',
  DotsVertical = 'dots-vertical',
  DotsDrag = 'dots-drag',
  Download = 'download',
  Edit = 'edit',
  EllipsisHorizontal = 'ellipsis-horizontal',
  ExclamationCircle = 'exclamation-circle',
  Filter = 'filter',
  Fire = 'fire',
  Flag = 'flag',
  Gift = 'gift',
  Globe = 'globe',
  Home = 'home',
  QuestionMarkCircle = 'question-mark-circle',
  InformationCircle = 'information-circle',
  InformationCircleSolid = 'information-circle-solid',
  ChartBar = 'chart-bar',
  ListBullet = 'list-bullet',
  Magnify = 'magnify',
  MinusSmall = 'minus-small',
  Pencil = 'pencil',
  PencilFilled = 'pencil-filled',
  Play = 'play',
  PlayCircle = 'play-cricle',
  Plus = 'plus',
  PlusSmall = 'plus-small',
  PuzzlePiece = 'puzzle-piece',
  RectangleStack = 'rectangle-stack',
  Redo = 'redo',
  Sort = 'sort',
  Sparkles = 'sparkles',
  Star = 'star',
  Trash = 'trash',
  User = 'user',
  UserAdd = 'user-add',
  UserGroup = 'user-group',
  Upload = 'upload',
  Undo = 'undo'
}

// TODO: get rid of this disaster
export const ICONS_BY_KEY: Record<
  ICONS_KEY,
  (props: IconProps) => JSX.Element
> = {
  [ICONS_KEY.ArchiveBox]: Icons.ArchiveBox,
  [ICONS_KEY.ArrowPointingOut]: Icons.ArrowPointingOut,
  [ICONS_KEY.ArrowSmallDown]: Icons.ArrowSmallDown,
  [ICONS_KEY.ArrowSmallLeft]: Icons.ArrowSmallLeft,
  [ICONS_KEY.QuestionMarkCircle]: Icons.QuestionMarkCircle,
  [ICONS_KEY.ArrowSmallRight]: Icons.ArrowSmallRight,
  [ICONS_KEY.ArrowTrendingUp]: Icons.ArrowTrendingUp,
  [ICONS_KEY.ArrowUturnRight]: Icons.ArrowUturnRight,
  [ICONS_KEY.CheckCircle]: Icons.CheckCircle,
  [ICONS_KEY.ChevronDown]: Icons.ChevronDown,
  [ICONS_KEY.CheckBadge]: (props: IconProps) => <CheckBadgeIcon {...props} />,
  [ICONS_KEY.ChevronLeft]: Icons.ChevronLeft,
  [ICONS_KEY.ChevronRight]: Icons.ChevronRight,
  [ICONS_KEY.ChevronUp]: Icons.ChevronUp,
  [ICONS_KEY.CircleArrowDown]: Icons.CircleArrowDown,
  [ICONS_KEY.Close]: Icons.Close,
  [ICONS_KEY.CloudArrowDown]: Icons.CloudArrowDown,
  [ICONS_KEY.Cog]: Icons.Cog,
  [ICONS_KEY.Cog8Tooth]: Icons.Cog8Tooth,
  [ICONS_KEY.Copy]: Icons.Copy,
  [ICONS_KEY.CubeTransparent]: Icons.CubeTransparent,
  [ICONS_KEY.CursorArrowRays]: Icons.CursorArrowRays,
  [ICONS_KEY.Db]: Icons.Db,
  [ICONS_KEY.Detail]: Icons.Detail,
  [ICONS_KEY.DocumentCheck]: Icons.DocumentCheck,
  [ICONS_KEY.Dot]: Icons.Dot,
  [ICONS_KEY.DotsVertical]: Icons.DotsVertical,
  [ICONS_KEY.DotsDrag]: Icons.DotsDrag,
  [ICONS_KEY.Download]: Icons.Download,
  [ICONS_KEY.Edit]: Icons.Edit,
  [ICONS_KEY.EllipsisHorizontal]: Icons.EllipsisHorizontal,
  [ICONS_KEY.ExclamationCircle]: Icons.ExclamationCircle,
  [ICONS_KEY.Filter]: Icons.Filter,
  [ICONS_KEY.Fire]: Icons.Fire,
  [ICONS_KEY.Flag]: Icons.Flag,
  [ICONS_KEY.Globe]: Icons.Globe,
  [ICONS_KEY.Gift]: Icons.Gift,
  [ICONS_KEY.Home]: Icons.Home,
  [ICONS_KEY.InformationCircle]: Icons.InformationCircle,
  [ICONS_KEY.InformationCircleSolid]: Icons.InformationCircleSolid,
  [ICONS_KEY.ListBullet]: Icons.ListBullet,
  [ICONS_KEY.Magnify]: Icons.Magnify,
  [ICONS_KEY.MinusSmall]: Icons.MinusSmall,
  [ICONS_KEY.Play]: Icons.Play,
  [ICONS_KEY.PlayCircle]: Icons.PlayCircle,
  [ICONS_KEY.Pencil]: Icons.Pencil,
  [ICONS_KEY.ChartBar]: Icons.ChartBar,
  [ICONS_KEY.PencilFilled]: Icons.PencilFilled,
  [ICONS_KEY.Plus]: Icons.Plus,
  [ICONS_KEY.PlusSmall]: Icons.PlusSmall,
  [ICONS_KEY.PuzzlePiece]: Icons.PuzzlePiece,
  [ICONS_KEY.RectangleStack]: Icons.RectangleStack,
  [ICONS_KEY.Redo]: Icons.Redo,
  [ICONS_KEY.Sort]: Icons.Sort,
  [ICONS_KEY.Sparkles]: Icons.Sparkles,
  [ICONS_KEY.Star]: Icons.Star,
  [ICONS_KEY.Trash]: Icons.Trash,
  [ICONS_KEY.User]: Icons.User,
  [ICONS_KEY.UserAdd]: Icons.UserAdd,
  [ICONS_KEY.UserGroup]: Icons.UserGroup,
  [ICONS_KEY.Upload]: Icons.Upload,
  [ICONS_KEY.Undo]: Icons.Undo
}
