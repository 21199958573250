import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const MinusSmall = (props: IconProps) => (
  <SVGContainer {...props} filled>
    <path
      clipRule='evenodd'
      d='M5.25 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z'
      fillRule='evenodd'
    />
  </SVGContainer>
)

export default MinusSmall
