import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const Pencil = (props: IconProps) => (
  <SVGContainer {...props}>
    <path
      d='M15.232 5.232L18.768 8.768M16.732 3.732C17.2009 3.2631 17.8369 2.99967 18.5 2.99967C19.1631 2.99967 19.7991 3.2631 20.268 3.732C20.7369 4.2009 21.0003 4.83687 21.0003 5.5C21.0003 6.16313 20.7369 6.7991 20.268 7.268L6.5 21.036H3V17.464L16.732 3.732Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SVGContainer>
)

export default Pencil
