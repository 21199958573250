import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const Filter = (props: IconProps) => (
  <SVGContainer {...props} filled>
    <path d='M20 7.85249V5.3262C19.9996 4.97464 19.8481 4.63754 19.579 4.38888C19.3097 4.14021 18.9448 4.00039 18.5642 4H5.43584C5.05522 4.00038 4.69024 4.14019 4.42102 4.38888C4.15194 4.63755 4.00042 4.97464 4 5.3262V7.85249C4.00096 8.30327 4.1592 8.74175 4.45109 9.10295L8.88217 14.5602H8.88231C8.90882 14.5928 8.92311 14.6325 8.92311 14.6733V18.6741C8.92325 19.1004 9.14522 19.5006 9.51967 19.7498C9.89425 19.999 10.3754 20.0667 10.8133 19.932L14.0953 18.9218V18.9217C14.3813 18.8339 14.6302 18.6651 14.8063 18.4392C14.9825 18.2133 15.0772 17.9419 15.0769 17.6636V14.6732C15.0769 14.6322 15.0913 14.5923 15.1181 14.5597L19.5488 9.10311C19.8407 8.7419 19.999 8.30343 19.9999 7.85265L20 7.85249ZM18.7692 7.85249C18.7687 8.05726 18.6969 8.25647 18.5642 8.42049L14.1341 13.8767H14.134C13.9479 14.1067 13.847 14.386 13.8464 14.6731V17.6635C13.8464 17.7451 13.7899 17.8175 13.7061 17.8433L10.4241 18.8535V18.8536C10.3616 18.8727 10.2928 18.8631 10.2394 18.8276C10.186 18.7921 10.1542 18.735 10.1541 18.6741V14.6732C10.1534 14.3863 10.0527 14.1073 9.86686 13.8772L5.8465 8.92606H16.513C16.7329 8.92606 16.936 8.81771 17.0459 8.64186C17.1558 8.46601 17.1558 8.24931 17.0459 8.07347C16.936 7.89762 16.7329 7.78928 16.513 7.78928H5.23112V5.32629C5.2314 5.22175 5.32302 5.137 5.4362 5.13687H18.5645C18.6777 5.137 18.7693 5.22175 18.7696 5.32629L18.7692 7.85249Z' />
  </SVGContainer>
)

export default Filter
