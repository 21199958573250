import { memo } from 'react'
import { TextSizes, TextWeights } from './constants'
import { TEXT_SIZES } from './text.styles'

type TextProps = {
  children: React.ReactNode
  className?: string
  color?: string | null
  size?: TextSizes
  weight?: TextWeights
}

const Text = (props: TextProps) => {
  const {
    children,
    className = '',
    color = 'text-primary',
    size = TextSizes.SM,
    weight = TextWeights.FONT_NORMAL
  } = props

  const textSizeClasses = TEXT_SIZES[size]

  return (
    <p className={`${color} ${textSizeClasses} ${weight} ${className}`}>
      {children}
    </p>
  )
}

export default memo(Text)
