import { Toaster as ToasterContainer } from 'react-hot-toast'

const Toaster = () => (
  <ToasterContainer
    position='top-center'
    gutter={8}
    toastOptions={{
      duration: 3000,
      style: { padding: '16px 24px' },
      success: {
        style: {
          border: '1px solid #84E1BC'
        }
      },
      error: {
        style: {
          border: '1px solid #F05252'
        }
      }
    }}
  />
)

export default Toaster
