const COMMON_STYLES = 'relative inline-flex rounded-md font-medium border'

export const BADGE_TYPES = {
  primary: `${COMMON_STYLES} bg-blue-100 text-blue-800 border-transparent`,
  secondary: `${COMMON_STYLES} bg-transparent text-primary-500 border border-primary-200`,
  default: `${COMMON_STYLES} bg-primary-100 text-primary-500 border-transparent`,
  success: `${COMMON_STYLES} bg-green-100 text-green-800 border-transparent`,
  danger: `${COMMON_STYLES} bg-red-100 text-red-800 border-transparent`,
  warning: `${COMMON_STYLES} bg-yellow-100 text-yellow-800 border-transparent`,
  disable: `${COMMON_STYLES} bg-primary-50 text-primary-300 border-primary-100`,
  custom: `${COMMON_STYLES}`
}

export const BADGE_SIZES = {
  xs: 'text-xs px-2 py-1',
  sm: 'text-sm px-3 py-1'
}
