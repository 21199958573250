import { DatabaseTableName } from '@/types'

export const QueryKeys: Record<DatabaseTableName, Record<string, string>> = {
  achievements: {
    get: 'GET_ACHIEVEMENTS'
  },
  activities: {
    get: 'GET_ACTIVITIES'
  },
  challenge_results: {
    get: 'GET_CHALLENGE_RESULTS'
  },
  challenges: {
    get: 'GET_CHALLENGES'
  },
  comments: {},
  comments_mentions: {},
  companies: {},
  csv_mappers: {},
  custom_fields: {
    get: 'GET_CUSTOM_FIELDS'
  },
  data_sources: {},
  deals: {
    get: 'GET_DEALS'
  },
  deals_audit_log: {},
  'email-leads': {},
  executions: {},
  invitations: {
    get: 'GET_INVITATIONS',
    insert: 'INSERT_INVITATION',
    update: 'UPDATE_INVITATION'
  },
  invoices: {
    get: 'GET_INVOICES'
  },
  issues: {
    get: 'GET_ISSUES',
    insert: 'INSERT_ISSUES',
    update: 'UPDATE_ISSUES'
  },
  kpis: {
    get: 'GET_KPIS'
  },
  kpis_tables: {
    get: 'GET_KPIS_TABLES'
  },
  kpis_tables_teams: {
    get: 'GET_KPIS_TABLES_TEAMS'
  },
  metric_values: {
    get: 'GET_METRIC_VALUES'
  },
  metrics: {
    get: 'GET_METRICS'
  },
  notifications: {
    get: 'GET_NOTIFICATIONS'
  },
  notifications_status: {},
  organizations: {
    get: 'GET_ORGANIZATIONS'
  },
  organizations_profiles: {
    get: 'GET_ORGANIZATION_PROFILES'
  },
  payout_adjustments: {
    get: 'GET_PAYOUT_ADJUSTMENTS'
  },
  payout_validations: {
    get: 'GET_PAYOUT_VALIDATIONS'
  },
  payouts: {
    get: 'GET_PAYOUTS'
  },
  payouts_audit_log: {},
  plans: {
    get: 'GET_PLANS'
  },
  plans_drafts: {},
  products: {},
  profile_org_variable_salary: {
    get: 'GET_PROFILE_ORG_VARIABLE_SALARY'
  },
  profiles: {
    get: 'GET_PROFILES'
  },
  rewards: {
    get: 'GET_REWARDS'
  },
  statements: {
    get: 'GET_STATEMENTS'
  },
  statement_approvals: {
    get: 'GET_STATEMENTS_APPROVALS'
  },
  table_config: {
    get: 'GET_TABLE_CONFIG'
  },
  tags: {},
  target_achievement: {},
  target_period_amount: {},
  target_profile_amount: {},
  target_team_amount: {},
  targets: {
    get: 'GET_TARGETS'
  },
  teams: {
    get: 'GET_TEAMS'
  },
  teams_challenges: {
    get: 'GET_TEAMS_CHALLENGES'
  },
  teams_plans: {
    get: 'GET_TEAMS_PLANS'
  },
  teams_profiles: {
    get: 'GET_TEAMS_PROFILES'
  },
  teams_tags: {
    get: 'GET_TEAMS_TAGS'
  },
  teams_targets: {
    get: 'GET_TEAMS_TARGETS'
  },
  template: {
    get: 'GET_TEMPLATES',
    insert: 'INSERT_TEMPLATE',
    update: 'UPDATE_TEMPLATE'
  },
  template_tags: {},
  validation_steps: {
    get: 'GET_VALIDATION_STEPS'
  },
  whistles: {
    get: 'GET_WHISTLES'
  },
  profile_org_start_date: {
    get: 'GET_PROFILE_ORG_START_DATE'
  }
}
