const COMMON_STYLES = 'hover:underline'

export const BUTTON_LINK_TYPES = {
  primary: `${COMMON_STYLES} text-neutral-900 hover:text-primary/80`,
  default: `${COMMON_STYLES} text-primary-700 hover:text-primary-800 underline`
}

export const BUTTON_LINK_SIZES = {
  xs: 'text-xs',
  sm: 'text-sm',
  base: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl'
}
