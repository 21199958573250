const COMMON_STYLES =
  'box-content flex space-x-2 items-center px-6 md:px-4 py-1.5 cursor-pointer hover:bg-neutral-50 md:hover:bg-neutral-800'

export const MENU_ITEM = {
  regular: `${COMMON_STYLES} text-neutral-700 md:text-neutral-200  `,
  active: `${COMMON_STYLES} bg-gradient-to-r from-neutral-50 md:from-neutral-700 border-l-2 border-highlight-400 text-highlight-400 md:border-highlight-300 md:text-highlight-300 `
}

export const MENU_ITEM_WITH_CHILDREN = {
  regular: `${COMMON_STYLES} text-neutral-700 md:text-neutral-200  `,
  active: `${COMMON_STYLES} bg-neutral-50 md:bg-gray-900 text-neutral-700 md:text-highlight-300 `,
  childActive: `${COMMON_STYLES} bg-neutral-50 md:bg-gray-900 border-l-2 border-highlight-400 text-highlight-400 md:text-highlight-300 md:border-highlight-300 `
}

export const SUBMENU_ITEM = {
  regular: `${COMMON_STYLES} text-neutral-700 md:text-neutral-200 `,
  active: `${COMMON_STYLES} text-highlight-400 md:text-highlight-300 `
}
