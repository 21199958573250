import { Button } from '@/src/stories/Button'
import { useState } from 'react'
import { t } from '@lingui/macro'
import { Mention } from 'primereact/mention'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { Database } from '@/db_types'
import { Profile } from '@/types'
import { useOrgContext } from '@/hooks/state/organization'
import Avatar from '@/src/ui/avatar'

interface CommentBoxProps {
  insertComment: (comment: string, mentions: string[]) => void
  onCancel?: () => void
  sendBtnLabel?: string
}

const CommentBox = ({
  insertComment,
  onCancel,
  sendBtnLabel
}: CommentBoxProps) => {
  const [newComment, setNewComment] = useState<string>()
  const [suggestions, setSuggestions] = useState<(Profile & { key: string })[]>(
    []
  )
  const [mentionedUsers, setMentionedUsers] = useState<{
    [index: string]: string
  }>({})
  const selectedOrganizationId = useOrgContext(state => state.id)
  const supabase = useSupabaseClient<Database>()

  const searchProfiles = async (search: string) => {
    const { data } = await supabase
      .from('profiles')
      .select('*, organizations_profiles!inner(*)')
      .eq('organizations_profiles.organization_id', selectedOrganizationId!)
      .ilike('name', `%${search}%`)

    const profiles =
      data?.map(p => {
        const { organizations_profiles, ...rest } = p
        return { ...rest, key: rest.email.replace('@', '_at_') }
      }) ?? []

    setSuggestions(profiles)
  }

  const itemTemplate = (suggestion: Profile) => {
    return (
      <div className='p-2'>
        <Avatar profileData={suggestion} withDetails />
      </div>
    )
  }

  const saveComment = () => {
    const comment = Object.keys(mentionedUsers).reduce(
      (acc, u) => acc.replace(`@${u}`, `@[${mentionedUsers[u]}]`),
      newComment ?? ''
    )
    const mentions = [...comment.matchAll(/@\[([0-9a-z-]+)]/g)].map(m => m[1])
    insertComment(comment, mentions)
  }

  return (
    <div>
      <Mention
        trigger={'@'}
        value={newComment}
        onChange={e => setNewComment((e.target as any).value)}
        suggestions={suggestions}
        placeholder={t`Type '@' to mention people`}
        field='key'
        itemTemplate={itemTemplate}
        onSearch={e => searchProfiles(e.query)}
        onSelect={e =>
          setMentionedUsers({
            ...mentionedUsers,
            [e.suggestion.key]: e.suggestion.id
          })
        }
        rows={3}
        panelStyle={{
          width: '100%'
        }}
        inputClassName='rounded-md border-primary-100 text-sm font-light w-full'
      />
      <div className='mt-6 flex items-center justify-end gap-2'>
        {!!onCancel && (
          <Button
            hierarchy={'secondary'}
            size={'small'}
            onPress={() => {
              setNewComment(undefined)
              onCancel?.()
            }}
            label={t`Cancel`}></Button>
        )}
        <Button
          size={'small'}
          isDisabled={!newComment}
          onPress={saveComment}
          disabled={!newComment}
          label={sendBtnLabel ?? t`Send`}></Button>
      </div>
    </div>
  )
}

export default CommentBox
