import { IconProps } from "../constants";
import SVGContainer from "./svg-container";

const Undo = (props: IconProps) => (
  <SVGContainer {...props} filled>
    <path fillRule="evenodd"
          d="M9.53 2.47a.75.75 0 010 1.06L4.81 8.25H15a6.75 6.75 0 010 13.5h-3a.75.75 0 010-1.5h3a5.25 5.25 0 100-10.5H4.81l4.72 4.72a.75.75 0 11-1.06 1.06l-6-6a.75.75 0 010-1.06l6-6a.75.75 0 011.06 0z"
          clipRule="evenodd" />
  </SVGContainer>
);

export default Undo;
