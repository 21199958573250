import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const ArrowSmallDown = (props: IconProps) => (
  <SVGContainer {...props} filled>
    <path
      fillRule='evenodd'
      d='M10 5a.75.75 0 01.75.75v6.638l1.96-2.158a.75.75 0 111.08 1.04l-3.25 3.5a.75.75 0 01-1.08 0l-3.25-3.5a.75.75 0 111.08-1.04l1.96 2.158V5.75A.75.75 0 0110 5z'
      clipRule='evenodd'
    />
  </SVGContainer>
)

export default ArrowSmallDown
