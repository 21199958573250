import {
  Dialog as RCADialog,
  DialogProps as RCADialogProps,
  Heading
} from 'react-aria-components'
import { Button } from '@/src/stories/Button'
import { tv } from 'tailwind-variants'
import { forwardRef, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface DialogProps extends RCADialogProps {
  title?: string
  buttonCloseLabel?: string
  buttonActionLabel?: string
  onAction?: () => void
  onCloseWithBtn?: () => void
  icon?: ReactNode
  restrictWidth?: boolean
  noPadding?: boolean
  actionButtonDisabled?: boolean
  submitButton?: boolean
}

const dialogStyles = tv({
  base: 'p-6 bg-neutral-0 rounded shadow space-y-10 outline-none focus:outline-none',
  variants: {
    restrictWidth: {
      true: 'max-w-[400px]'
    },
    noPadding: {
      true: 'p-0'
    }
  }
})

const iconStyles = tv({
  base: 'w-12 shrink-0 h-12 flex rounded-full items-center justify-center',
  variants: {
    isAlert: {
      true: 'bg-critical-100 text-critical-500',
      false: 'bg-brand-100 text-brand-500'
    }
  }
})

const headingStyles = tv({
  base: "text-neutral-900 text-left text-[21px] font-bold font-['Plus Jakarta Sans'] break-normal"
})

const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      title,
      buttonCloseLabel,
      buttonActionLabel,
      children,
      onCloseWithBtn,
      icon,
      restrictWidth = true,
      noPadding = false,
      ...props
    },
    ref
  ) => {
    return (
      <RCADialog
        {...props}
        className={twMerge(
          dialogStyles({
            restrictWidth,
            noPadding
          }),
          props.className
        )}>
        {({ close }) => (
          <div ref={ref}>
            <div
              className={
                'flex self-stretch justify-start items-start flex-col'
              }>
              <div className={'flex flex-row justify-start items-center gap-4'}>
                {icon && (
                  <span
                    className={iconStyles({
                      isAlert: props.role === 'alertdialog'
                    })}>
                    {icon}
                  </span>
                )}
                {!!title && (
                  <Heading className={headingStyles()} slot='title'>
                    {title}
                  </Heading>
                )}
              </div>
              <>{children}</>
            </div>

            {(buttonCloseLabel || !!props.onAction) && (
              <span className={'w-full flex items-center gap-4 justify-end'}>
                {buttonCloseLabel && (
                  <Button
                    hierarchy={'secondary'}
                    label={buttonCloseLabel}
                    onPress={() => {
                      ;(!onCloseWithBtn ? close : onCloseWithBtn)()
                    }}
                  />
                )}
                {(!!props.onAction || props.submitButton) && (
                  <Button
                    isDisabled={props.actionButtonDisabled}
                    type={props.submitButton ? 'submit' : 'button'}
                    hierarchy={'primary'}
                    destructive={props.role === 'alertdialog'}
                    label={buttonActionLabel}
                    onPress={props.onAction}
                  />
                )}
              </span>
            )}
          </div>
        )}
      </RCADialog>
    )
  }
)

Dialog.displayName = 'Dialog'

export default Dialog
