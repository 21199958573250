import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const PlayCircle = (props: IconProps) => (
  <SVGContainer {...props}>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
    />
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z'
    />
  </SVGContainer>
)

export default PlayCircle
