import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const Sort = (props: IconProps) => (
  <SVGContainer {...props} filled>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.1271 4C16.3197 4 16.5044 4.07897 16.6405 4.21953C16.7767 4.36009 16.8532 4.55073 16.8532 4.74951V17.3314L18.7412 15.2327C18.8722 15.0869 19.0539 15.0008 19.2464 14.9933C19.3417 14.9896 19.4368 15.0053 19.5262 15.0395C19.6156 15.0738 19.6976 15.1258 19.7675 15.1927C19.8375 15.2597 19.894 15.3402 19.9337 15.4296C19.9735 15.5191 19.9959 15.6158 19.9995 15.7141C20.0031 15.8125 19.9879 15.9106 19.9547 16.0029C19.9216 16.0952 19.8711 16.1799 19.8063 16.2521L16.6596 19.7498C16.5916 19.8255 16.5092 19.8858 16.4176 19.9271C16.326 19.9684 16.2271 19.9897 16.1271 19.9897C16.0271 19.9897 15.9282 19.9684 15.8365 19.9271C15.7449 19.8858 15.6625 19.8255 15.5946 19.7498L12.4479 16.2521C12.3169 16.1063 12.2474 15.9128 12.2547 15.7141C12.2619 15.5155 12.3454 15.3279 12.4866 15.1927C12.6278 15.0576 12.8153 14.9858 13.0078 14.9933C13.2002 15.0008 13.3819 15.0869 13.5129 15.2327L15.4009 17.3314V4.74951C15.4009 4.55073 15.4774 4.36009 15.6136 4.21953C15.7498 4.07897 15.9345 4 16.1271 4Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.8729 20C8.68031 20 8.49561 19.921 8.35943 19.7805C8.22325 19.6399 8.14674 19.4493 8.14674 19.2505L8.14674 6.66864L6.25873 8.76728C6.12777 8.91306 5.94606 8.99916 5.75359 9.00666C5.65829 9.01037 5.56322 8.99467 5.47379 8.96046C5.38437 8.92625 5.30235 8.87419 5.23242 8.80726C5.16249 8.74033 5.10601 8.65983 5.06622 8.57038C5.02643 8.48092 5.0041 8.38425 5.0005 8.28588C4.9969 8.18751 5.01211 8.08938 5.04526 7.99708C5.07841 7.90478 5.12885 7.82012 5.19369 7.74794L8.34038 4.25021C8.40836 4.17453 8.49075 4.11416 8.58238 4.07287C8.67401 4.03158 8.77292 4.01027 8.8729 4.01027C8.97289 4.01027 9.07179 4.03158 9.16342 4.07287C9.25506 4.11416 9.33744 4.17453 9.40542 4.25021L12.5521 7.74794C12.6831 7.89372 12.7526 8.08722 12.7453 8.28588C12.738 8.48454 12.6546 8.67208 12.5134 8.80726C12.3722 8.94243 12.1847 9.01416 11.9922 9.00666C11.7997 8.99916 11.618 8.91306 11.4871 8.76728L9.59906 6.66864L9.59906 19.2505C9.59906 19.4493 9.52255 19.6399 9.38637 19.7805C9.25019 19.921 9.06549 20 8.8729 20Z'
    />
  </SVGContainer>
)

export default Sort
