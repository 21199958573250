import React, { ReactElement } from 'react'
import {
  Button as RACButton,
  ButtonProps as RACButtonProps,
  composeRenderProps
} from 'react-aria-components'
import { chipBaseStyles } from '@/src/stories/utils'

interface AssistChipProps extends RACButtonProps {
  /**
   * Button contents
   */
  leadingAdornment?: ReactElement
  squared?: boolean
}

const AssistChip = ({ leadingAdornment, ...props }: AssistChipProps) => {
  return (
    <RACButton
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        chipBaseStyles({
          ...renderProps,
          squared: props.squared,
          className
        })
      )}>
      <>
        {leadingAdornment}
        {props.children}
      </>
    </RACButton>
  )
}

export default AssistChip
