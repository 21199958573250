import { HEADING_SIZES } from './heading.styles'

export enum HeadingSizes {
  BASE = 'base',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  '2XL' = '2xl',
  '3XL' = '3xl',
  '4XL' = '4xl',
  '5XL' = '5xl'
}

export enum HeadingWeights {
  FONT_LIGHT = 'font-light',
  FONT_NORMAL = 'font-normal',
  FONT_MEDIUM = 'font-medium',
  FONT_SEMIBOLD = 'font-semibold',
  FONT_BOLD = 'font-bold'
}

type HeadingProps = {
  children: React.ReactNode | React.ReactNode[]
  className?: string
  color?: string | null
  level: 1 | 2 | 3 | 4 | 5
  size?: HeadingSizes
  weight?: HeadingWeights
} & React.HTMLAttributes<{}>

const Heading = (props: HeadingProps) => {
  const {
    children,
    className = '',
    color = 'text-primary',
    level = 1,
    size = 'lg',
    weight = 'font-bold',
    ...attrs
  } = props

  const headingSizeClasses = HEADING_SIZES[size]
  const HeadingLevelElement: any = `h${level}`

  return (
    <HeadingLevelElement
      {...attrs}
      className={`${headingSizeClasses} ${color} ${weight} ${className}`}>
      {children}
    </HeadingLevelElement>
  )
}

export default Heading
