const DolfinIcon = ({
  color = 'text-primary',
  size = 32
}: {
  color?: string
  size?: number
}) => {
  return (
    <svg
      className={color}
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.01562 5.14844V11.9326C8.34583 11.7301 10.6914 11.466 13.0299 11.6508C15.0128 11.9231 17.0149 12.4884 18.6916 13.6198C19.4754 14.1803 19.9503 15.0606 20.6732 15.6782C21.27 16.0241 22.4296 16.224 22.3294 17.1144C22.0996 18.0677 20.9877 17.5545 20.3267 17.5002C19.0654 17.5122 17.8481 17.9434 16.6095 18.1498C15.4713 18.4511 14.0907 18.4541 13.2693 19.4215C12.6267 21.1852 11.5857 22.9543 9.83987 23.7903C9.46739 23.9858 7.95703 24.7182 8.50143 23.7903C9.12322 22.9202 9.57376 21.878 9.65215 20.802C9.37616 20.1916 8.41877 21.2173 7.97053 21.3823C7.30777 21.8281 6.65917 22.2943 6.01562 22.7676V28.1484H14.79C16.3633 28.1484 17.8257 27.8332 19.1771 27.2028C20.5487 26.5724 21.7388 25.7285 22.7473 24.671C23.7761 23.6135 24.5728 22.3934 25.1376 21.0105C25.7225 19.6073 26.015 18.1533 26.015 16.6484C26.015 15.1232 25.7225 13.6692 25.1376 12.2864C24.5526 10.8832 23.7458 9.66303 22.7171 8.6259C21.7085 7.56843 20.5185 6.72448 19.1469 6.09407C17.7954 5.46365 16.3431 5.14844 14.79 5.14844H6.01562Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DolfinIcon
