export enum BadgeSizes {
  XS = 'xs',
  SM = 'sm'
}

export enum BadgeTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DEFAULT = 'default',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  DISABLE = 'disable',
  CUSTOM = 'custom'
}
