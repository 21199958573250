import { t } from '@lingui/macro'
import { Button, Icons, Text } from '@/src/ui'
import { BtnTypes } from '@/src/ui/button'
import { TextSizes, TextWeights } from '@/src/ui/typography/text'
import { MouseEventHandler } from 'react'

type MessageConfirmationProps = {
  description?: string | JSX.Element
  messageType?: BtnTypes
  onCancel: MouseEventHandler<HTMLButtonElement> | (() => void)
  onConfirm: MouseEventHandler<HTMLButtonElement> | (() => void)
  primaryCTALabel?: string
  secondaryCTALabel?: string
  title?: string
}

const MessageConfirmation = ({
  description = t`Would you like to proceed?`,
  messageType = BtnTypes.PRIMARY,
  onCancel,
  onConfirm,
  primaryCTALabel = t`Yes`,
  secondaryCTALabel = t`Cancel`,
  title
}: MessageConfirmationProps) => {
  return (
    <div className='flex flex-col items-center gap-2 text-center w-full'>
      <div className='w-[42px] h-[42px] flex justify-center'>
        <Icons.ExclamationCircle color='text-primary-400' size={42} />
      </div>
      {!!title && (
        <Text
          size={TextSizes.LG}
          className='text-center'
          weight={TextWeights.FONT_BOLD}
          color='gray-500'>
          {title}
        </Text>
      )}
      {typeof description === 'string' ? (
        <Text color='gray-400' size={TextSizes.BASE} className='text-center'>
          {description}
        </Text>
      ) : (
        description
      )}
      <div className='flex flex-row gap-4 justify-center mt-6'>
        <Button btnType={BtnTypes.SECONDARY} onClick={onCancel}>
          {secondaryCTALabel}
        </Button>
        <Button btnType={messageType} onClick={onConfirm}>
          {primaryCTALabel}
        </Button>
      </div>
    </div>
  )
}

export default MessageConfirmation
