import { Heading, Icons } from '@/src/ui'
import { HeadingSizes } from '../../typography/heading'

type HeaderProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  title?: string
}

const DrawerHeader = ({ title, onClick }: HeaderProps) => {
  return (
    <div
      className={
        'relative w-full flex items-center mb-8 ' +
        (!title ? 'justify-end' : 'justify-between')
      }>
      {title && (
        <Heading color='text-primary-800' level={5} size={HeadingSizes.XL}>
          {title}
        </Heading>
      )}
      <button
        className='text-primary-400 bg-transparent rounded-lg text-sm items-center flex-0'
        onClick={onClick}
        type='button'>
        <Icons.Close color='text-primary/60' size={16} />
      </button>
    </div>
  )
}

export default DrawerHeader
