import { createStore, useStore } from 'zustand'
import { createContext, useContext, useRef } from 'react'
import { DefinedPartial, Organization, ProfileWithJoins } from '@/types'
import {
  getOrgRole,
  getTeamsIds,
  hasTeamFullViewAccess,
  isManagerOfTeam
} from '@/utils/roles'
import { Enums } from '@/db_types'

type State = {
  id?: number
  organizations: Organization[]
  setOrganizationId: (id: number) => void
  selectedOrganization?: Organization
  setSelectedOrganization?: (organization: Organization) => void

  profile?: ProfileWithJoins
  setProfile: (profile: ProfileWithJoins) => void
  getRole: () => Enums<'org_role'> | undefined
  hasFullView: (teamId?: number) => boolean
  isManager: (teamId?: number) => boolean
  getTeamsIds: () => number[]
}

export const createOrgStore = (initProps?: Partial<State>) => {
  return createStore<State>()((set, get) => ({
    id: undefined,
    organizations: [],

    selectedOrganization: undefined,

    setOrganizationId: (id: number) => set({ id }),
    setSelectedOrganization: (organization: Organization) =>
      set({ selectedOrganization: organization }),

    profile: undefined,
    setProfile: (profile: ProfileWithJoins) => set({ profile }),

    getRole: () => getOrgRole(get().profile!, get().selectedOrganization!.id),

    hasFullView: teamId =>
      hasTeamFullViewAccess(
        get().profile!,
        get().selectedOrganization!.id,
        teamId
      ),

    isManager: teamId =>
      isManagerOfTeam(get().profile!, get().selectedOrganization!.id, teamId),

    getTeamsIds: () =>
      getTeamsIds(get().profile!, get().selectedOrganization!.id),

    ...initProps
  }))
}

const OrgStoreContext = createContext<State | null>(null)

type OrgProviderProps = React.PropsWithChildren<DefinedPartial<State>>

export function OrgProvider({ children, ...props }: OrgProviderProps) {
  const storeRef = useRef<State>()
  if (storeRef && !storeRef.current) {
    ;(storeRef.current as any) = createOrgStore(props)
  }
  return (
    <OrgStoreContext.Provider value={storeRef.current ?? null}>
      {children}
    </OrgStoreContext.Provider>
  )
}

export function useOrgContext<T>(
  selector: (state: State) => T,
  equalityFn?: (left: T, right: T) => boolean
): T {
  const store = useContext(OrgStoreContext)
  if (!store) throw new Error('Missing OrgStoreContext.Provider in the tree')

  // TODO - fix this
  // @ts-ignore
  return useStore(store, selector, equalityFn)
}
