import React, { useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { Bell01, X } from '@untitled-ui/icons-react'
import { IconWrapper } from '@/src/stories/IconWrapper'
import { Trans } from '@lingui/macro'
import useNotifications from '@/hooks/useNotifications'
import { useQueryClient } from 'react-query'
import { QueryKeys } from '@/hooks/queryKeys'
import { getCurrentBreakpoint } from '@/src/stories/utils'
import { Modal } from '@/src/stories/Modal'
import { Button, DialogTrigger } from 'react-aria-components'
import { zIndexes } from '@/src/constants/z-index'
import { Database } from '@/db_types'
import Dialog from '@/src/stories/Dialog'

const Notifications = () => {
  const [showNotifications, setShowNotifications] = useState(false)
  const currentBreakpoint = getCurrentBreakpoint()
  const { unreadNotifications } = useNotifications({})
  const notificationsRef = useRef<HTMLDivElement>(null)
  useClickAway(notificationsRef, () => setShowNotifications(false))

  return (
    <DialogTrigger>
      <div
        className='relative cursor-pointer z-20 -mr-2'
        onClick={() => setShowNotifications(!showNotifications)}>
        <IconWrapper
          Icon={Bell01}
          height={18}
          className={'text-white md:text-brand-700'}
        />
        {unreadNotifications > 0 && (
          <p className='absolute -left-0.5 -top-1 text-[0.5rem] w-3 h-3 font-semibold rounded-full bg-red-500 text-center text-white align-middle'>
            {unreadNotifications}
          </p>
        )}
      </div>
      {currentBreakpoint === 'sm' && (
        <Modal
          isOpen={showNotifications}
          onOpenChange={setShowNotifications}
          isDismissable={true}>
          <Dialog
            noPadding
            restrictWidth={false}
            className='bg-neutral-50 border-0 outline-0 overflow-hidden rounded'>
            <NotificationContentBlock
              onClose={() => setShowNotifications(false)}
            />
          </Dialog>
        </Modal>
      )}
      {currentBreakpoint !== 'sm' && showNotifications && (
        <div
          ref={notificationsRef}
          className={`absolute top-6 right-6 w-[300px] rounded border border-neutral-300 shadow-xl ${zIndexes.MODALS}`}>
          <Dialog
            noPadding
            restrictWidth={false}
            className='bg-neutral-50 border-0 outline-0 overflow-hidden rounded'>
            <NotificationContentBlock
              onClose={() => setShowNotifications(false)}
            />
          </Dialog>
        </div>
      )}
    </DialogTrigger>
  )
}

interface NotificationContentBlockProps {
  onClose?: () => void
  entityId?: number
  profileId?: string
  entityType?: string
  type?: Database['public']['Enums']['notification_type']
}

export const NotificationContentBlock: React.FC<
  NotificationContentBlockProps
> = ({ onClose, entityId, profileId, entityType, type }) => {
  const queryClient = useQueryClient()
  const {
    notifications,
    writingComment,
    setWritingComment,
    NotificationItem,
    unreadNotifications
  } = useNotifications({
    enabled: true,
    entity_id: entityId,
    entity_type: entityType,
    type: type,
    profile_id: profileId
  })
  return (
    <div className=' border-neutral-300 shadow'>
      {(!!onClose || !entityId) && (
        <div className='sticky border-0 z-10 bg-neutral-0 top-0  p-2.5 font-semibold flex flex-row w-full justify-between items-center'>
          <div>
            {!entityId && (
              <div className='flex flex-row items-center'>
                <Trans>Inbox</Trans>
                <span
                  className={`block rounded-md text-white ml-2 text-xs align-middle py-1 px-2 h-6${
                    unreadNotifications > 0 ? ' bg-red-500' : ' bg-black'
                  }`}>
                  {unreadNotifications}
                </span>
              </div>
            )}
          </div>

          {!!onClose && (
            <Button
              onPress={() => onClose()}
              className={'focus:outline-none cursor-pointer justify-self-end'}>
              <X className={'h-5 w-5'} />
            </Button>
          )}
        </div>
      )}

      <div className='max-h-[80vh] md:max-h-[60vh] overflow-y-scroll w-full'>
        {notifications?.length === 0 && (
          <p className='p-6 text-primary-500 text-sm'>
            <Trans>You don&apos;t have any notifications yet</Trans>
          </p>
        )}
        {notifications?.map(notification => (
          <NotificationItem
            key={`notification_${notification.id}`}
            notification={notification}
            writingComment={writingComment}
            setWritingComment={setWritingComment}
            reloadNotifications={async () => {
              await queryClient.invalidateQueries(QueryKeys.notifications.get)
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default Notifications
