import { IconProps } from '../constants'
import SVGContainer from './svg-container'
import React from 'react'

const CircleArrowDown = (props: IconProps) => (
  <SVGContainer {...props}>
    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
    <g
      id='SVGRepo_tracerCarrier'
      strokeLinecap='round'
      strokeLinejoin='round'></g>
    <g id='SVGRepo_iconCarrier'>
      <path
        d='M9.293 14.707a.997.997 0 0 0 1.414 0l4.243-4.243a1 1 0 1 0-1.414-1.414L11 11.586V6a1 1 0 0 0-2 0v5.586L6.464 9.05a1 1 0 1 0-1.414 1.414l4.243 4.243zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z'
        fill={props.color || 'currentColor'}></path>
    </g>
  </SVGContainer>
)

export default CircleArrowDown
