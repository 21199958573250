import { MENU_ITEM } from '@/src/components/sidebar/sidebar.styles'
import { MessageQuestionSquare } from '@untitled-ui/icons-react'
import React from 'react'
import { t } from '@lingui/macro'
import { Button, DialogTrigger } from 'react-aria-components'
import { Modal } from '@/src/stories/Modal'
import Dialog from '@/src/stories/Dialog'
import { zIndexes } from '@/src/constants/z-index'

const Help = ({ isInSidebar }: { isInSidebar: boolean }) => {
  return (
    <div
      className={
        isInSidebar ? 'flex' : `fixed bottom-5 left-5 ${zIndexes.HELP}`
      }>
      <DialogTrigger>
        <Button
          className={`${isInSidebar && MENU_ITEM.regular} outline-0 w-full`}>
          <MessageQuestionSquare
            width={isInSidebar ? 18 : 40}
            className={'shrink-0'}
          />
          {isInSidebar && (
            <p
              className={
                'md:hidden md:group-hover:block text-xs font-medium whitespace-nowrap overflow-hidden'
              }>
              {t`Support`}
            </p>
          )}
        </Button>
        <Modal>
          <Dialog
            title={t`We’re here for you!`}
            buttonCloseLabel={t`Close`}
            className={'min-w-[40vw]'}
            icon={<MessageQuestionSquare width={20}></MessageQuestionSquare>}
            role={'dialog'}>
            <div className="pb-8 gap-4 flex flex-col w-full text-left text-neutral-700 text-base font-normal font-['Inter'] leading-tight">
              <p>
                {t`If you have any questions or need assistance, please contact us at:`}
                <br />
                <a
                  className={'text-teal-500'}
                  target={'_blank'}
                  href='mailto:support@heydolfin.com'>
                  support@heydolfin.com
                </a>
              </p>
              <p
                className={
                  'max-w-prose'
                }>{t`Provide as much detail as possible, and feel free to attach screenshots for additional context. This helps us assist you better and respond more effectively. Please ensure to add your contact information so we can reach out if needed.`}</p>

              <p>{t`Our general response time is between 24 to 48 hours on working days.`}</p>
              <p>{t`Thanks for making our software better!`}</p>
            </div>
          </Dialog>
        </Modal>
      </DialogTrigger>
    </div>
  )
}

export default Help
