import React, { CSSProperties } from 'react'
import {
  Button,
  ButtonProps as RACButtonProps,
  TooltipTrigger
} from 'react-aria-components'
import { tv } from 'tailwind-variants'
import Tooltip from '@/src/stories/Tooltip'
import { twMerge } from 'tailwind-merge'
import { useRouter } from 'next/router'

export type AvatarSizes = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | '3xl'

interface AvatarProps extends RACButtonProps {
  size?: AvatarSizes
  label?: string
  imgUrl?: string
  name?: string
  email?: string
  withTooltip?: boolean
  showDetails?: boolean
  highlighted?: boolean
  isLink?: boolean
  userId?: string
}

const textStyles = tv({
  base: "uppercase text-center text-neutral-500 font-medium font-['Inter']",
  variants: {
    size: {
      xs: 'text-xs leading-[18px]',
      s: 'text-sm leading-tight',
      m: 'text-base leading-normal',
      l: 'text-lg leading-7',
      xl: 'text-xl leading-[30px]',
      xxl: 'text-2xl leading-loose',
      '3xl': 'text-3xl leading-[45px]'
    }
  }
})

const styles = tv({
  base: 'overflow-hidden rounded-full outline-none flex items-center justify-center gap-2 shrink-0',
  variants: {
    highlighted: {
      true: 'ring-warning-400',
      false: 'ring-neutral-0'
    },
    imgUrl: {
      false: 'bg-neutral-50 border border-neutral-300'
    },
    size: {
      xs: 'w-6 h-6 ring-[1px] shadow',
      s: 'w-8 h-8 ring-[2px] shadow',
      m: 'w-10 h-10 ring-[3px] shadow',
      l: 'w-12 h-12 ring-[3px] shadow',
      xl: 'w-14 h-14 ring-[4px] shadow-lg',
      xxl: 'w-16 h-16 ring-[5px] shadow-lg',
      '3xl': 'w-20 h-20 ring-[6px] shadow-xl'
    },
    onPress: {
      true: 'cursor-pointer'
    }
  }
})

const containerStyles = tv({
  base: 'flex items-center gap-2 focus:outline-none',
  variants: {
    isLink: {
      true: 'cursor-pointer hover:opacity-80 transition-opacity duration-200 ease-in-out'
    }
  }
})

const Avatar = ({ showDetails = true, ...props }: AvatarProps) => {
  const router = useRouter()
  const containerRef = React.useRef<HTMLDivElement>(null)

  if (!props.imgUrl && !props.label) {
    throw new Error('Avatar must have either a label or an image')
  }

  const onClick = props.isLink
    ? () => {
        router.push(`/dashboard?page=user&id=${props.userId}`)
      }
    : undefined
  const onBtnPress = () => {
    containerRef.current?.click()
  }

  return (
    <div ref={containerRef} onClick={onClick}>
      <TooltipTrigger
        isDisabled={!props.withTooltip || !(props.name || props.email)}
        delay={300}>
        <Button
          onPress={onBtnPress}
          className={containerStyles({
            isLink: !!props.isLink && !!props.userId
          })}>
          <div
            style={props.style as CSSProperties}
            className={twMerge(
              props.className as string,
              styles({
                highlighted: !!props.highlighted,
                size: props.size,
                imgUrl: !!props.imgUrl,
                onPress: !!props.onPress || props.isLink
              })
            )}>
            {props.imgUrl && (
              <img
                src={props.imgUrl}
                alt={props.label}
                className={'object-cover w-full h-full'}
              />
            )}

            {!props.imgUrl && props.label && (
              <span
                className={textStyles({
                  size: props.size
                })}>
                {props.label}
              </span>
            )}
          </div>

          {(props.name || props.email) && showDetails && (
            <div
              className={
                'flex-col text-left justify-start items-start overflow-hidden flex'
              }>
              {props.name && (
                <p className="block truncate ... self-stretch w-full text-neutral-900 text-xs font-medium font-['Plus Jakarta Sans'] leading-tight">
                  {props.name}
                </p>
              )}
              {props.email && (
                <p className="block truncate ... self-stretch w-full text-neutral-600 text-xs font-medium font-['Plus Jakarta Sans'] leading-tight">
                  {props.email}
                </p>
              )}
            </div>
          )}
        </Button>

        <Tooltip placement={'top'}>
          <div className='sm:max-w-[20vw] break-all'>
            <Tooltip.Title>{props.name ?? ''}</Tooltip.Title>
            <Tooltip.SupportiveText>{props.email ?? ''}</Tooltip.SupportiveText>
          </div>
        </Tooltip>
      </TooltipTrigger>
    </div>
  )
}

export default Avatar
