export enum ButtonLinkTypes {
  PRIMARY = 'primary',
  DEFAULT = 'default'
}

export enum ButtonLinkSizes {
  XS = 'xs',
  SM = 'sm',
  BASE = 'base',
  LG = 'lg',
  XL = 'xl'
}

export enum ButtonLinkWeights {
  FONT_LIGHT = 'font-light',
  FONT_NORMAL = 'font-normal',
  FONT_MEDIUM = 'font-medium',
  FONT_SEMILBOLD = 'font-semibold',
  FONT_BOLD = 'font-bold'
}
