import { getCardStylesBySize } from '../../utils'
import { CARD_SIZES } from '@/src/ui/card/card.styles'

type CardBodyProps = {
  children: [React.ReactNode]
  size: keyof typeof CARD_SIZES
}

const CardBody = ({ children, size }: CardBodyProps) => {
  return (
    <div className={`${getCardStylesBySize(size)} relative`}>{children}</div>
  )
}

export default CardBody
