import { IconProps } from '../constants'
import SVGContainer from './svg-container'

const ArrowSmallLeft = (props: IconProps) => (
  <SVGContainer {...props} filled>
    <path
      clipRule='evenodd'
      d='M17.9994 11.9983C17.9994 12.237 17.9046 12.4659 17.7358 12.6347C17.567 12.8035 17.3381 12.8983 17.0994 12.8983H9.13382L11.7234 15.2503C11.8126 15.3312 11.8847 15.4292 11.9353 15.5385C11.986 15.6478 12.0142 15.7661 12.0182 15.8865C12.0223 16.0069 12.0022 16.1269 11.959 16.2393C11.9159 16.3518 11.8506 16.4544 11.767 16.5412C11.6835 16.628 11.5834 16.6971 11.4726 16.7444C11.3619 16.7918 11.2428 16.8164 11.1223 16.8169C11.0019 16.8174 10.8825 16.7937 10.7714 16.7472C10.6603 16.7007 10.5597 16.6324 10.4754 16.5463L6.27542 12.6463C6.18832 12.5623 6.11904 12.4617 6.07171 12.3504C6.02439 12.239 6 12.1193 6 11.9983C6 11.8773 6.02439 11.7576 6.07171 11.6462C6.11904 11.5349 6.18832 11.4343 6.27542 11.3503L10.4754 7.4503C10.5597 7.3642 10.6603 7.29589 10.7714 7.24941C10.8825 7.20292 11.0019 7.17922 11.1223 7.17969C11.2428 7.18017 11.3619 7.20482 11.4726 7.25218C11.5834 7.29954 11.6835 7.36865 11.767 7.45541C11.8506 7.54217 11.9159 7.64482 11.959 7.75727C12.0022 7.86973 12.0223 7.9897 12.0182 8.11008C12.0142 8.23046 11.986 8.3488 11.9353 8.45808C11.8847 8.56737 11.8126 8.66538 11.7234 8.7463L9.13382 11.0983H17.0994C17.3381 11.0983 17.567 11.1931 17.7358 11.3619C17.9046 11.5307 17.9994 11.7596 17.9994 11.9983Z'
      fillRule='evenodd'
    />
  </SVGContainer>
)

export default ArrowSmallLeft
